<template>
    <v-dialog
        persistent
        :value="dialog"
        max-width="300"
    >
        <v-card>
            <v-card-title class="d-block">
                <div class="mb-2 d-flex justify-center">
                    <v-icon x-large class="icono" color="blue">mdi-emoticon-happy</v-icon>
                </div>
                <h1 style="text-align:center; color:blue" class="subtitle-2">{{title}}</h1>
            </v-card-title>
            <v-card-text class="pa-0 pl-1 pr-1 d-block">
                <h1 style="text-align:center" class="subtitle-2">{{subtitle}}</h1>
            </v-card-text>
            <v-card-actions class="pa-2 d-flex justify-center">
                <v-btn depressed :small="true" color="info" @click="$emit('click')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        dialog: Boolean,
        title: String,
        subtitle: String
    }
}
</script>
<style scoped>
.icono {
    animation-duration: 600ms;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        transform: rotate(360deg);
        opacity: 0;
    }
    to {
        transform: rotate(0);
        opacity: 1;
    }
}
</style>