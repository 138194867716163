<template>
    <v-dialog persistent :value="dialog" max-width="380">
        <v-card class="pb-2">
            <v-card-title class="d-block">
                <div class="mb-2 d-flex justify-center">
                    <v-icon x-large class="icono" color="red">mdi-close-circle</v-icon>
                </div>
                <h1 style="text-align:center; color:red" class="subtitle-2">ERROR</h1>
            </v-card-title>
            <v-card-text class="pa-0 pl-1 pr-1 d-block">
                <h1 style="text-align:center" class="subtitle-2" v-for="(error, index) in smsError" :key="index">
                    {{error}}
                </h1>
            </v-card-text>
            <v-card-actions class="pa-2 d-flex justify-center" v-if="accion">
                <v-btn depressed :small="true" color="error" @click="$emit('click')">Aceptar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        dialog: Boolean,
        smsError: Array,
        accion: Boolean
    }
}
</script>
<style scoped>
.icono {
    animation-duration: 600ms;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        transform: rotate(360deg);
        opacity: 0;
    }
    to {
        transform: rotate(0);
        opacity: 1;
    }
}
</style>