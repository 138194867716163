<template>
    <div class="d-flex pa-1">
        <v-app-bar 
            app
            dark
            dense
            class="pa-0 header-api"
            style="z-index:100"
        >
            <v-row class="d-flex ma-0">
                <div class="d-flex align-center mr-2">
                    <img height="30px" src="/logos/logosmall.png" />
                </div>
                <router-link style="text-decoration:none !important;" to="home" class="d-flex align-center">
                    <h1 class="header__title">GATONET</h1>
                </router-link>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    {{formatUser()}}
                    <v-btn
                        fab
                        depressed
                        small
                        class="mr-3 ml-2"
                        :ripple="false"
                        to="cart"
                    >
                        <v-icon>mdi-cart</v-icon>
                    </v-btn>
                    <v-menu
                        transition="slide-x-transition"
                        :rounded="true"
                        offset-y
                        left
                        class="mt-2"
                    >
                        <template v-slot:activator="{ attrs, on }">
                            <v-icon
                                class="pa-1"
                                v-bind="attrs"
                                v-on="on"
                            >mdi-view-headline</v-icon>
                        </template>
                        <v-list>
                            <v-list-item style="background:#f0f0f0"
                                    color="#181818"
                                    v-for="(option, index) in listMenu"
                                    :key="index"
                                    :to="option.router"
                                    class="mb-1"
                                >
                                <v-list-item-icon class="mr-1">
                                    <v-icon small color="#181818">{{option.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>{{option.title}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item style="background:#f0f0f0" @click="closedSession()">
                                <v-list-item-icon class="mr-1">
                                    <v-icon small color="#181818">mdi-close-circle</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title v-text="'Cerrar Sesión'"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </v-row>
        </v-app-bar>
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="false"
        />
        <LoaderBar :dialog="dialogBar" message="Cerrando Sesión" />
    </div>
</template>
<script>
import SmsError from './SmsError'
import { User } from '../../models'
import { responseCase } from '../../mixins'
import LoaderBar from './LoaderBar'
export default {
    mixins: [ responseCase ],
    components: {
        SmsError,
        LoaderBar
    },
    props: {
        listMenu: {}
    },
    data: () => ({
        smsError: [],
        dialogError: false,
        dialogBar: false,
        nombreUs: JSON.parse(localStorage.getItem('empresauser')).nombre
    }),
    methods: {
        formatUser() {
            var name = '' + this.nombreUs
            var newname = ''
            for(var i = 0; i < name.length; i++) {
                if (name[i] === ' ') {
                    break
                } else {
                    newname = newname + name[i]
                }
            }
            return newname
        },
        smsErrorDatos(error) {
            this.smsError = this.smsErrorResponse(error)
            this.dialogBar = false
            this.dialogError = true
        },
        closedSession() {
            this.dialogBar = true
            User.closedSession().then(response => {
                this.dialogBar = false
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            }).catch((error)=>{
                this.smsErrorDatos(error)
            })
        }
    }
}
</script>
<style scoped>
    .menulista h1{
        font-size: 16px;
        color: white;
        letter-spacing: 1.5px;
        font-weight: 400;
    }
</style>