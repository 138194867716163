<template>
    <v-select
            :single-line="enLinea"
            :menu-props="{ bottom: true, offsetY: true }"
            :background-color="backgroundColor"
            dense
            outlined
            :items="items"
            :item-text="itemText"
            :item-value="itemValue"
            :label="label"
            :hide-details="detallesOcultos"
            :append-icon="icono"
            @change="(e)=>(this.$emit('change', e))"
            :rules="rules"
            :value="value"
            :disabled="bloquear"
    >
    </v-select>
</template>
<script>
export default {
    model:{
        event: 'change'
    },
    props:{
        enLinea:Boolean,
        backgroundColor:String,
        icono:String,
        items:Array,
        itemText:String,
        itemValue: String,
        label:String,
        detallesOcultos:Boolean,
        rules:Array,
        value:[Number,String],
        bloquear:Boolean
    }
}
</script>