<template>
    <div>
        <Button :to="to"
            :xsmall="xsmall"
            :small="small"
            :colorbtn="colorbtn"
            :disabled="disabled"
            :sinsombra="true"
            :redondo="redondo"
            :outlined="outlined"
            @click="$emit('click')"
        >
            <Icon :small="true" :icono="icono" :color="coloricono" />
        </Button>
    </div>
</template>
<script>
import { Button, Icon } from '../molecules'
export default {
    components: {
        Button,
        Icon
    },
    props: {
        icono: String,
        to: String,
        colorbtn: String,
        coloricono: String,
        redondo: Boolean,
        xsmall: Boolean,
        small: Boolean,
        disabled: Boolean,
        outlined: Boolean
    }
}
</script>
