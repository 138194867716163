import axios from 'axios'
import { API_LOCAL } from '../plugins/apiUrls'
import { LoginStore } from '../store/moduls'
export default class Pins {
    static updatePins(parameters) {
        return axios.put(API_LOCAL.Pin + '/update', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
}