import axios from 'axios'
import { API_LOCAL } from '../plugins/apiUrls'
import { LoginStore } from '../store/moduls'

export default class Purchase {
    static addBuyApiRazer(parameters) {
        return axios.post(API_LOCAL.Purchase + '/add-buy-razer', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static getPurchases(failed, date_one, date_two, search, page){
        const data = `date1=${date_one}&date2=${date_two}&search=${search}&page=${page}&failed=${failed}`
        return axios.get(API_LOCAL.Purchase + '/list?' + data, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
}
