<template>
    <div class="d-flex justify-center mt-10">
        <div class="pa-2">
            <Table
                :listaPins="pins"
                @click="kickProduct"
            />
            <TitleNoData class="mt-1" v-if="pins.length==0" title="SIN PINS" />
            <div class="d-flex justify-center mt-1">
                <Button
                    :small="true"
                    :sinsombra="true"
                    :outlined="true"
                    v-for="dataButton in listButtons"
                    :key="dataButton.title"
                    colorbtn="#06143bb6"
                    :disabled="pins.length==0 ? true : false"
                    @click="performAction(dataButton)"
                    class="ma-2"
                >
                    <v-icon small color="rgba(0, 0, 0, 0.8)">{{dataButton.icon}}</v-icon>
                </Button>
            </div>
        </div>
        <v-dialog max-width="500px" persistent v-model="dialogCellphone">
            <v-card>
                <CardTitle title="Enviar PINs" @click="closeForm()" />
                <v-card-text class="pa-2" style="color:rgba(0,0,0,0.92)">
                    <div class="d-flex mb-2">
                        <h1 class="text__card--title">CANTIDAD DE PINs:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pins.length}}</h2>
                    </div>
                    <div
                        class="d-flex"
                        v-for="(pin, index) in pins"
                        :key="index"
                    >
                        <h1 class="text__card--title">PIN:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pin.pin}}</h2>
                    </div>
                    <v-form
                            class="mt-3"
                            v-on:submit.prevent="sendPins()"
                            ref="formcellphone"
                            v-model="valid"
                            lazy-validation>
                        <TextFieldForm
                            class="mt-2"
                            v-model="cellphone"
                            :clearable="true"
                            label="Celular"
                            @search="sendPins()"
                            :rules="[v => validateField(v) || 'Introduzca un número de Celular']"
                        />
                    </v-form>
                </v-card-text>
                <CardActions accion="ENVIAR" @click="sendPins() "/>
            </v-card>
        </v-dialog>
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <SmsConfirm
            :titleSms="textConfirm"
            :dialog="dialogConfirm"
            @clicksi="performOperationAdd()"
            @click="dialogConfirm=false"
        />
        <LoaderBar :dialog="dialogBar" :message="smsLoader" />
        <SmsCorrect
                @click="frameCorrect=false"
                :snakCorrecto="frameCorrect"
                :timeout="4000"
                :text="txtEvent"
        />
        <v-dialog max-width="500px" persistent v-model="dialogPins">
            <v-card>
                <v-card-text class="pa-2" style="color:rgba(0,0,0,0.92)">
                    <div class="d-flex justify-center mb-2 mt-2">
                        <h1 style="color:rgba(255,20,0,1); text-align:center;" class="text__h1--primary">
                            PINs enviados por Whatsapp pero no actualizados</h1>
                    </div>
                    <div
                        class="d-flex"
                        v-for="(pin, index) in pinsPeding"
                        :key="index"
                    >
                        <h1 class="text__card--title">{{'PIN N°'+(index+1)+':'}}</h1>
                        <h2 class="text__card--subtitle ml-1">{{pin.pin}}</h2>
                    </div>
                </v-card-text>
                <v-card-actions class="pa-2 d-flex justify-center form-acciones">
                    <Button
                        :small="true"
                        :sinsombra="true"
                        colorbtn="white"
                        @click="ignoreData()"
                    >
                        IGNORAR
                    </Button>
                    <Button
                        :small="true"
                        :sinsombra="true"
                        colorbtn="white"
                        @click="updatePinsOlds()"
                    >
                        ACTUALIZAR
                    </Button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Table, TitleNoData, Button, TextFieldForm } from '../../components/molecules'
import {
    CardTitle,
    CardActions,
    SmsError,
    SmsConfirm,
    LoaderBar,
    SmsCorrect
} from '../../components/organisms'
import { mapState, mapMutations } from 'vuex'
import { rules, responseCase } from '../../mixins'
import { Pins } from '../../models'
export default {
    mixins: [rules, responseCase],
    components: {
        Table,
        TitleNoData,
        Button,
        TextFieldForm,
        CardTitle,
        CardActions,
        SmsError,
        SmsConfirm,
        LoaderBar,
        SmsCorrect
    },
    data: () => ({
        listButtons: [
            {
                icon: 'mdi-window-maximize',
                param: 0
            },
            {
                icon: 'mdi-sticker-check',
                param: 1
            },
            {
                icon: 'mdi-whatsapp',
                param: 2
            }
        ],
        valid: true,
        cellphone: null,
        dialogCellphone: false,
        dialogError: false,
        smsError: [],
        actionError: false,
        noAccess: false,
        paramWhatsapp: false,
        dialogConfirm: false,
        textConfirm: '',
        dialogBar: false,
        smsLoader: '',
        frameCorrect: false,
        txtEvent: [],
        pinsPeding: [],
        dialogPins: false
    }),
    computed: {
        ...mapState('Cart', ['pins'])
    },
    methods: {
        ...mapMutations('Cart', ['kickPin', 'clearListPins']),
        kickProduct(e) {
            this.kickPin(e)
        },
        performAction(button) {
            if (button.param === 0) {
                this.clearListPins()
            } else {
                if (button.param === 1) {
                    this.textConfirm = '¿Está seguro de dar por vendido la lista de PINs seleccionado?'
                    this.dialogConfirm = true
                } else {
                    this.dialogCellphone = true
                }
            }
        },
        closeForm() {
            this.$refs.formcellphone.resetValidation()
            this.cellphone = null
            this.dialogCellphone = false
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                if (this.paramWhatsapp) {
                    this.dialogCellphone = true
                    this.paramWhatsapp = false
                }
            }
        },
        validateField(fact) {
            if (!this.emptyField(fact) && !this.withSpaces(fact) && this.validNumber(fact)) {
                return true
            } else { return false }
        },
        validateData() {
            var validate = true
            if (!this.$refs.formcellphone.validate()) { validate = false }
            return validate
        },
        sendPins() {
            this.dialogCellphone = false
            this.paramWhatsapp = true
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                localStorage.setItem('send_pins', JSON.stringify(this.pins))
                var smsWhatsapp='Lista%20PINs%20:%0A-----------------------%0A'
                for(var i = 0; i < this.pins.length; i++) {
                    smsWhatsapp = smsWhatsapp + (i+1) + '.-%20' + this.pins[i].pin + '%0A'
                }
                smsWhatsapp = smsWhatsapp + '-----------------------%0A'
                window.open("https://api.whatsapp.com/send?phone=591" + this.cellphone + "&text="+smsWhatsapp)
                this.closeForm()
            }
        },
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        showEvent(txt) {
            this.txtEvent = txt
            this.frameCorrect = true
            setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        updatePins(parameters) {
            this.smsLoader = 'Realizando Operación'
            this.dialogBar = true
            Pins.updatePins(parameters).then(response => {
                localStorage.removeItem('send_pins')
                this.dialogBar = false
                this.showEvent(['OERACIÓN EXITOSA!',
                                'Los PINs fueron actualizados como vendidos'])
            }).catch((error) => {
                this.processError(error)
            })
        },
        performOperationAdd() {
            this.dialogConfirm = false
            var parameters = {
                pins: this.pins
            }
            this.updatePins(parameters)
        },
        ignoreData() {
            localStorage.removeItem('send_pins')
            this.dialogPins = false
        },
        updatePinsOlds() {
            this.dialogPins = false
            var parameters = {
                pins: this.pinsPeding
            }
            this.updatePins(parameters)
        }
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('send_pins'))) {
            this.pinsPeding = JSON.parse(localStorage.getItem('send_pins'))
            this.dialogPins = true
        }
    }
}
</script>
<style scoped>
    .form-acciones{
        background: rgb(0,0,0) !important;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(2,11,45,1) 100%) !important;
    }
</style>