<template>
    <div class="d-flex mt-1 mb-1">
        <v-menu
            v-model="menu1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        background-color="white"
                        outlined
                        dense
                        hide-details
                        v-model="formattedDate1"
                        label="Del"
                        readonly
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                    @change="selectedDates(1)"
                    locale="es-mx"
                    v-model="date1"
                    @input="menu1 = false"
                    color="#071849"
            ></v-date-picker>
        </v-menu>
        <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
        >
            <template v-slot:activator="{ on }">
                <v-text-field
                        background-color="white"
                        class="ml-2"
                        outlined
                        dense
                        hide-details
                        v-model="formattedDate2"
                        label="Al"
                        readonly
                        v-on="on"
                ></v-text-field>
            </template>
            <v-date-picker
                    @change="selectedDates(2)"
                    locale="es-mx"
                    v-model="date2"
                    @input="menu2 = false"
                    color="#071849"
            ></v-date-picker>
        </v-menu>
    </div>
</template>
<script>
    export default {
        name: "PickerDates",
        data: vm => ({
            menu1: false,
            menu2: false,
            date1: null,
            date2: null,
        }),
        mounted() {
            this.date2 = this.dateNow()
            this.date1 = this.dateNow()
        },
        methods: {
            selectedDates(numericDate) {
                if (numericDate === 1) {
                    if (new Date(this.date1).getTime() > new Date(this.date2).getTime()) {
                        this.date2 = this.date1
                    }
                } else {
                    if (new Date(this.date2).getTime() < new Date(this.date1).getTime()) {
                        this.date1 = this.date2
                    }
                }
                this.$emit('onChangingDate', {date1: this.date1, date2: this.date2})
            },
            formatDate(date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}/${month}/${year}`
            },
            dateNow() {
                let today = new Date()
                let dd = today.getDate()
                let mm = today.getMonth() + 1
                let yyyy = today.getFullYear()
                if (dd < 10) { dd = '0' + dd }
                if (mm < 10) { mm = '0' + mm }
                return today = yyyy + '-' + mm + '-' + dd
            }
        },
        computed: {
            formattedDate1() {
                return this.formatDate(this.date1)
            },
            formattedDate2() {
                return this.formatDate(this.date2)
            }
        }
    }
</script>
