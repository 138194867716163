<template>
    <v-card-title class="d-flex pa-2 form-encabezado">
        <h1 class="text__h1--primary">{{title}}</h1>
        <v-spacer></v-spacer>
        <OptionButton
            v-if="btnEvery"
            colorbtn="white"
            coloricono="#071849"
            icono="mdi-card-plus"
            :redondo="true"
            :xsmall="true"
            class="mr-1"
            @click="$emit('clickevery')"
        />
        <OptionButton
            colorbtn="white"
            coloricono="#ca0000"
            icono="mdi-close"
            :redondo="true"
            :xsmall="true"
            @click="$emit('click')"
        />
    </v-card-title>
</template>
<script>
import { OptionButton } from '../organisms'
export default {
    components: {
        OptionButton
    },
    props: {
        title: String,
        btnEvery: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
    .form-encabezado{
        background: rgb(0,0,0) !important;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(2,11,45,1) 100%) !important;
    }
    .form-encabezado h1{
        color:rgb(247, 241, 241);
        font-weight: 400;
    }
</style>