<template>
    <div class="d-flex justify-center" v-if="dialog">
        <div>
            <h1 class="text__card--title mb-1">{{message}}</h1>
            <div class="d-flex justify-center">
                <div class="preloader"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        message: String,
        dialog: Boolean
    },
    computed: {
        valueDialog: function() {
            setTimeout(() => {
                return this.dialog
            }, 200)
        }
    }
}
</script>
<style scoped>
    .preloader {
        width: 45px;
        height: 45px;
        border: 6px solid rgb(230, 230, 230);
        border-top: 10px solid #06193b;
        border-radius: 50%;
        animation-name: girar;
        animation-duration: 2s;
        animation-iteration-count: infinite;
    }
    @keyframes girar {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>
