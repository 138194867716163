<template>
    <div class="d-flex" style="width:100%">
        <TextFieldForm
            label="Buscar"
            :hideDetails="true"
            :singleLine="true"
            :value="value"
            background="white"
            :clearable="true"
            @search="$emit('search')"
            @input="(e)=>(this.$emit('input', e))"
        />
        <Button
            class="ml-1"
            :redondo="true"
            :small="true"
            :sinsombra="true"
            colorbtn="#071849"
            @click="$emit('search')"
        >
            <Icon color="white" icono="mdi-magnify" />
        </Button>
    </div>
</template>
<script>
import { TextFieldForm, Button, Icon} from '../molecules'
export default {
    components: {
        TextFieldForm,
        Button,
        Icon
    },
    props: {
        value: String
    },
    model: {
        prop: 'value',
        event: 'input'
    }
}
</script>