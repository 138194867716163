<template>
    <div class="card-currency d-block">
        <div class="d-flex">
            <h1 class="text__card--title">ESTADO DE CUENTA:</h1>
            <h2 class="text__card--subtitle ml-1" v-if="user.habilitado">
                Habilitado
                <div class="ml-2 cart-currency__cuadro" style="background: green"></div>
            </h2>
            <h2 class="text__card--subtitle ml-1" v-else>
                Inhabilitado
                <div class="ml-2 cart-currency__cuadro" style="background: red"></div>
            </h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">NOMBRE:</h1>
            <h2 class="text__card--subtitle ml-1">{{user.nombre}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CI:</h1>
            <h2 class="text__card--subtitle ml-1" v-if="user.ci!=null">{{user.ci}}</h2>
            <h2 class="text__card--subtitle ml-1" v-else>----------</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">DIRECCION:</h1>
            <h2 class="text__card--subtitle ml-1">{{user.direccion}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CELULAR:</h1>
            <h2 class="text__card--subtitle ml-1" v-if="user.celular!=null">{{user.celular}}</h2>
            <h2 class="text__card--subtitle ml-1" v-else>----------</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CATEGORÍA:</h1>
            <h2 class="text__card--subtitle ml-1">{{user.txtcategoria}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">USERNAME:</h1>
            <h2 class="text__card--subtitle ml-1">{{user.username}}</h2>
        </div>
        <div class="cart-currency__line"></div>
        <div class="d-flex justify-center">
            <OptionButton
                :outlined="true"
                v-for="(opcion, index) in listaOpciones" :key="index"
                :icono="iconoOpcion(opcion)"
                coloricono="#06143bb6"
                colorbtn="#06143bb6"
                :small="true"
                class="ma-1"
                @click="$emit('click', enviarUser(user, opcion.param))"
            />
        </div>
    </div>
</template>
<script>
import { OptionButton } from '../organisms'
export default {
    components: {
        OptionButton
    },
    props:{
        user: {}
    },
    data: () => ({
        opciones: [
            {
                icono:'mdi-whatsapp',
                iconob:'mdi-whatsapp',
                param: 1,
                hab: false
            },{
                icono:'mdi-eye',
                iconob: 'mdi-eye-off',
                param: 2,
                hab: true
            },{
                icono:'mdi-pencil',
                iconob:'mdi-pencil',
                param: 3,
                hab: true
            },{
                icono:'mdi-account-key',
                iconob:'mdi-account-key',
                param: 4,
                hab: true
            }
        ]
    }),
    computed: {
        listaOpciones: function () {
            if (this.user.celular === null) {
                return this.opciones.filter(value => {
                    return value.hab === true
                })
            } else {
                return this.opciones
            }
        }
    },
    methods: {
        enviarUser(user, parametro) {
            var newuser = {
                usuario: user,
                param: parametro
            }
            return newuser
        },
        iconoOpcion(opcion) {
            if (opcion.param === 2) {
                if (this.user.habilitado) { return opcion.icono}
                else { return opcion.iconob }
            } else {
                return opcion.icono
            }
        }
    }
}
</script>
