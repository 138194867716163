<template>
    <v-btn
        :x-small="xsmall"
        :small="small"
        :large="large"
        :color="colorbtn"
        :ripple="false"
        :fab="redondo"
        :dark="blanco"
        :type="type"
        :depressed="sinsombra"
        :outlined="outlined"
        :disabled="disabled"
        :loading="loading"
        :to="to"
        :tile="tile"
        :rounded="rounded"
        @click="$emit('click')">
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
  props: {
    xsmall: Boolean,
    small: Boolean,
    large: Boolean,
    colorbtn: String,
    redondo: Boolean,
    blanco: Boolean,
    type: String,
    sinsombra: Boolean,
    outlined: Boolean,
    disabled: Boolean,
    loading: Boolean,
    to: String,
    tile: Boolean,
    rounded: Boolean
  }
}
</script>
