<template>
    <v-dialog
            :value="dialog"
            persistent
            width="300"
        >
            <v-card
              color="#020b2d"
              dark
            >
            <v-card-text class="pt-2" >
                <div class="text-center">
                   {{message}}
                </div>
                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
          </v-card>
    </v-dialog>
</template>
<script>
export default {
    name: 'LoaderBarra',
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        message: String
    }
}
</script>
