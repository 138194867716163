<template>
    <div class="pt-10 home">
        <div class="purchase home__title pa-2">
            <h1 class="text__h1--secundary">COMPRAS</h1>
            <v-spacer></v-spacer>
            <div class="purchaseOptions">
                <Search
                    class="purchaseOptions__search"
                    v-model="search"
                    @search="searchPurchase()"
                />
                <Dates @onChangingDate="getPurchasesDates" />   
            </div>
        </div>
        <div style="width:100%; overflow:hidden;">
            <div class="users-header__line"></div>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <CardPurchase
                v-for="purchase in listPurchase" :key="purchase.id"
                :purchase="purchase"
                class="ma-2"
                @clickdetail="viewDetail"
                @clickcoupon="clickcoupon"
            />
        </div>
        <LoaderCircle
            :dialog="dialogLoaderCircle"
            :message="smsLoader"
            @click="dialogLoaderCircle=false"
        />
        <v-dialog max-width="500px" persistent v-model="dialogDetail">
            <v-card color="white">
                <CardTitle :title="titleDetail" @click="dialogDetail = false" />
                <v-card-text style="color:rgba(0,0,0,0.92)" class="pa-2">
                    <div class="d-flex">
                        <h1 class="text__card--title">REVERSIBLE:</h1>
                        <h2 class="text__card--subtitle ml-1" v-if="product.isReversible">
                            Si
                            <div class="ml-2 cart-currency__cuadro" style="background: green"></div>
                        </h2>
                        <h2 class="text__card--subtitle ml-1" v-else>
                            No
                            <div class="ml-2 cart-currency__cuadro" style="background: red"></div>
                        </h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">COD. DE COMPRA:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.purchaseStatusCode}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">ID TRANSACCIÓN:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.referenceId}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">ID DE PAGO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.referenceId}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">PRECIO UNITARIO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.unitPrice}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">TOTAL PRECIO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.totalPrice}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">FECHA COMPRA RAZER:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.purchaseStatusDate}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">FECHA REGISTRO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.fecha}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">REGISTRADOR:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.usuario}}</h2>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <Pagination
            v-if="listPurchase.length>0 && !dialogBar"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            class="mt-2"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="changePage(false)"
            @clickgo="changePage(true)"
        />
        <LoaderBar :dialog="dialogBar" :message="smsLoader" />
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <TitleNoData class="mt-3" v-if="listPurchase.length==0 && !dialogLoaderCircle" title="SIN REGISTROS" />
        <v-dialog
            class="pa-0"
            persistent
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            v-model="dialogCoupons"
        >
            <v-card color="white">
                <CardTitle
                    :title="titleDetail"
                    :btnEvery="true"
                    @click="dialogCoupons=false"
                    @clickevery="addEvery()"
                />
                <div class="d-flex justify-center flex-wrap">
                    <CardCoupons
                        v-for="(coupon, index) in coupons" :key="index"
                        :coupon="coupon"
                        :num="(index+1)"
                        class="ma-2"
                        @click="addCart"
                    />
                </div>
                <TitleNoData class="mt-3" v-if="coupons.length==0" title="SIN CUPONES" />
            </v-card>
        </v-dialog>
        <SmsCorrect
            @click="frameCorrect=false"
            :snakCorrecto="frameCorrect"
            :timeout="1000"
            :text="txtEvent"
            :colorSucess="colorSucess"
        />
    </div>
</template>
<script>
import {
    CardPurchase,
    Dates,
    LoaderBar,
    SmsError,
    Pagination,
    Search,
    CardTitle,
    CardCoupons,
    SmsCorrect,
    LoaderCircle
} from '../../components/organisms'
import { Purchase } from '../../models'
import { TitleNoData } from '../../components/molecules'
import { animations, responseCase } from '../../mixins'
import { mapState, mapMutations } from 'vuex'
export default {
    mixins: [animations, responseCase],
    components: {
        CardPurchase,
        Dates,
        LoaderBar,
        SmsError,
        TitleNoData,
        Pagination,
        Search,
        CardTitle,
        CardCoupons,
        SmsCorrect,
        LoaderCircle
    },
    data: () => ({
        dialogBar: false,
        smsLoader: '',
        dialogError: false,
        smsError: [],
        actionError: false,
        noAccess: false,
        date_one: '',
        date_two: '',
        search: '',
        searchAltern: '',
        page: 0,
        last_page: 0,
        listPurchase: [],
        dialogDetail: false,
        product: {},
        titleDetail: '',
        dialogCoupons: false,
        coupons: [],
        frameCorrect: false,
        txtEvent: [],
        dialogLoaderCircle: false,
        colorSucess: 'success'
    }),
    computed: {
        ...mapState('Cart', ['pins'])
    },
    methods: {
        ...mapMutations('Cart', ['addPin']),
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            }
        },
        getPurchases(date_one, date_two, search, page) {
            this.smsLoader = 'Obteniendo Datos'
            this.dialogLoaderCircle = true
            Purchase.getPurchases(0, date_one, date_two, search, page).then(response => {
                this.listPurchase = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.dialogLoaderCircle = false
            }).catch((error)=>{
                this.processError(error)
            })
        },
        changePage(state) {
            var time = this.eventAnimationMouse(window.scrollY)
            this.animacionScroll(time)
            setTimeout(() => {
                this.listPurchase = []
                this.search = this.searchAltern
                if (state) {
                    this.getPurchases(this.date_one, this.date_two, this.search, ++this.page)
                } else {
                    this.getPurchases(this.date_one, this.date_two, this.search, --this.page)
                }
            }, time)
        },
        getPurchasesDates(fechas=null) {
            this.listPurchase = []
            this.date_one = fechas ? fechas.date1 : ''
            this.date_two = fechas ? fechas.date2 : ''
            this.getPurchases(this.date_one, this.date_two, this.search, 1)
        },
        searchPurchase() {
            this.listPurchase = []
            if (this.search !== null) {
                this.searchAltern = this.search
                this.getPurchases(this.date_one, this.date_two, this.search, 1)
            } else {
                this.search = ''
                this.searchAltern = ''
                this.getPurchases(this.date_one, this.date_two, '', 1)
            }
        },
        viewDetail(e) {
            this.dialogDetail = true
            this.product = e
            this.titleDetail = e.producto.productName
        },
        clickcoupon(e) {
            this.titleDetail = e.producto.productName
            this.coupons = e.coupons
            this.dialogCoupons = true
        },
        showEvent(txt, colorSucess) {
            this.frameCorrect = false
            setTimeout(() => {
                this.txtEvent = txt
                this.frameCorrect = true
                this.colorSucess = colorSucess
            }, 200)
        },
        noExistPin(pin) {
            var value = true
            for(var i = 0; i < this.pins.length; i++) {
                if (this.pins[i].id === pin.id) {
                    value = false
                    break
                }
            }
            return value
        },
        addCart(e) {
            this.frameCorrect = false
            setTimeout(()=>{
                if (this.noExistPin(e)) {
                    var dataFormat = {
                        product: this.titleDetail,
                        id: e.id,
                        pin: e.pin
                    }
                    this.addPin(dataFormat)
                    this.showEvent(['AGREGASTE EL PIN', e.pin], 'success')
                } else {
                    this.showEvent(['EL PIN YA ESTÁ AGREGADO', e.pin], 'error')
                }
            }, 300)
        },
        addEvery() {
            for(var i = 0; i < this.coupons.length; i++) {
                for (var k = 0; k < this.coupons[i].pins.length; k++) {
                    if (this.noExistPin(this.coupons[i].pins[k])) {
                        var dataFormat = {
                            product: this.titleDetail,
                            id: this.coupons[i].pins[k].id,
                            pin: `${this.coupons[i].pins[k].pin} - ${this.coupons[i].serials[k].serial}`
                        }
                        this.addPin(dataFormat)
                    }
                }
            }
            this.showEvent(['Se agregaron los PINs'], 'success')
        }
    },
    mounted() {
        this.getPurchases('', '', '', 1)
    }
}
</script>

