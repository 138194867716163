import qs from 'qs'
import axios from 'axios'
import { API_RAZER_GOLD, API_LOCAL } from '../plugins/apiUrls'
import { LoginStore } from '../store/moduls'

export default class Products {
    static getProductsLocal(search, currency, page) {
        return axios.get(API_LOCAL.Product + '/list?search=' + search +
            '&currency=' + currency + '&page=' + page, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static getProductsApi(parameters) {
        return axios.get(API_LOCAL.Product + '/list-api?applicationCode=' + parameters.applicationCode +
            '&version=' + parameters.version + '&signature=' + parameters.signature, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static addUpProducts(parameters) {
        return axios.post(API_LOCAL.Product + '/add-up', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static initialPurchase(parameters) {
        return axios.post(API_LOCAL.Product + '/initial-purchase', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static buyPins(parameters) {
        return axios.post(API_LOCAL.Product + '/buy-pins', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }



    static getProductsBuyPin(parameters) {
        var data = qs.stringify(parameters)
        return axios.post(API_RAZER_GOLD.PRODUCTS + '/list-api', data, {
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
            }
        }).then(
            response => {
                return response.data
            }
        )
    }
}