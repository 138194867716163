<template>
    <v-card-actions class="pa-2 d-flex justify-center form-acciones">
        <v-btn :small="true" :disabled="disabled" :loading="loading" :color="colorBtn" @click="$emit('click')">{{accion}}</v-btn>
    </v-card-actions>
</template>
<script>
export default {
    props: {
        accion: String,
        colorBtn: String,
        loading: Boolean,
        disabled: Boolean
    }
}
</script>
<style scoped>
    .form-acciones{
        background: rgb(0,0,0) !important;
        background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(2,11,45,1) 100%) !important;
    }
</style>