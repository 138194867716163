<template>
    <div class="blanco pt-2 pb-2">
        <div class="d-flex justify-center align-center contenidopag">
            <h1>{{titlePagination}}</h1>
            <OptionButton
                    :disabled="!condicionBack"
                    colorbtn="#071849"
                    coloricono="white"
                    class="ml-3"
                    :redondo="true"
                    :xsmall="true"
                    icono="mdi-chevron-left"
                    @click="$emit('clickback')"/>
            <OptionButton
                    :disabled="!condicionGo"
                    colorbtn="#071849"
                    coloricono="white"
                    class="ml-2"
                    :redondo="true"
                    :xsmall="true"
                    icono="mdi-chevron-right"
                    @click="$emit('clickgo')"/>
        </div>
    </div>
</template>
<script>
import { OptionButton } from '../organisms'
export default {
    components: {
        OptionButton
    },
    props: {
        titlePagination: String,
        condicionBack: Boolean,
        condicionGo: Boolean
    }
}
</script>
<style scoped>
    .contenidopag h1{
        color: rgb(4, 18, 66);
        font-size: 18px;
        font-weight: 400;
        text-shadow: 0px 0px 4px rgb(194, 195, 202);
    }
</style>