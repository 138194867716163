<template>
    <div class="pt-10 home">
        <div class="users__containerOptions home__title pa-2">
            <h1 class="text__h1--secundary">CONFIGURACIONES</h1>
        </div>
        <div style="width:100%; overflow:hidden;">
            <div class="users-header__line"></div>
        </div>
        <div class="pl-2 pt-2" v-for="(dataConfi, index) in dataConfigurations" :key="index">
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">CODIGO DE LA APLICACIÓN:</h1>
                <p class="text__p--primary">{{dataConfi.applicationCode}}</p>
            </div>
            <div class="d-flex align-center pa-1">
                <h1 class="text__h1--primary mr-1">LLAVE SECRETA:</h1>
                <p class="text__p--primary" v-if="showSecretKey">{{dataConfi.secretKey}}</p>
                <p class="text__p--primary" v-else>-------------</p>
                <OptionButton
                        class="ml-2"
                        @click="showSecretKey = !showSecretKey"
                        :icono="showSecretKey ? 'mdi-eye' : 'mdi-eye-off'"
                        :redondo="true"
                        :xsmall="true"
                        coloricono="white"
                        :colorbtn="showSecretKey ? 'green' : 'red'"
                />
            </div>
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">VERSIÓN:</h1>
                <p class="text__p--primary">{{dataConfi.version}}</p>
            </div>
            <v-btn outlined color="#063b16" @click="openForm(dataConfi)" small class="mt-2 ml-1">CAMBIAR CONFIGURACIONES</v-btn>
        </div>
        <v-dialog max-width="500px" persistent v-model="dialogForm">
            <v-card>
                <CardTitle title="Cambiar Configuraciones" @click="closeForm()" />
                <v-card-text>
                    <v-form
                            class="mt-2"
                            v-on:submit.prevent="confirUpConfi()"
                            ref="formconfigurations"
                            v-model="valid"
                            lazy-validation>
                        <TextFieldForm
                            class="mt-4 mb-3"
                            v-model="dataUpConfi.applicationCode"
                            :clearable="true"
                            label="Código de la Aplicación"
                            :counter="50"
                            :rules="[v => validateField(v) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=50 || 'El código de la aplicación no debe exeder los 50 dígitos']"
                        />
                        <TextFieldForm
                            class="mb-3"
                            v-model="dataUpConfi.secretKey"
                            :clearable="true"
                            :type="showKeyForm ? 'text' : 'password'"
                            label="Llave Secreta"
                            :icon="showKeyForm ? 'mdi-eye' : 'mdi-eye-off'"
                            :counter="50"
                            @clicksee="showKeyForm = !showKeyForm"
                            :rules="[v => validateField(v) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=50 || 'La llave secreta no debe exeder los 50 dígitos']"
                        />
                        <TextFieldForm
                            class="mb-3"
                            v-model="dataUpConfi.version"
                            :clearable="true"
                            :counter="10"
                            label="Llave Secreta"
                            :rules="[v => validateField(v) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=10 || 'La llave secreta no debe exeder los 10 dígitos']"
                        />
                    </v-form>
                </v-card-text>
                <CardActions accion="Actualizar" @click="confirUpConfi() "/>
            </v-card>
        </v-dialog>
        <LoaderBar :dialog="dialogBar" :message="smsLoader" />
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <SmsConfirm
                :titleSms="textConfirm"
                :dialog="dialogConfirm"
                @clicksi="performOperation()"
                @click="backFormError()"
        />
        <SmsCorrect
                @click="frameCorrect=false"
                :snakCorrecto="frameCorrect"
                :timeout="4000"
                :text="txtEvent"
        />
    </div>
</template>
<script>
import { Configurations } from '../../models'
import {
    OptionButton,
    SmsError,
    LoaderBar,
    CardTitle,
    CardActions,
    SmsConfirm,
    SmsCorrect
} from '../../components/organisms'
import { TextFieldForm } from '../../components/molecules'
import { rules, responseCase } from '../../mixins'
export default {
    mixins: [rules, responseCase],
    components: {
        OptionButton,
        SmsError,
        LoaderBar,
        CardTitle,
        CardActions,
        TextFieldForm,
        SmsConfirm,
        SmsCorrect
    },
    data:() => ({
        dialogForm: false,
        dataConfigurations: [],
        showSecretKey: false,
        dialogBar: false,
        smsLoader: '',
        smsError: [],
        actionError: false,
        noAccess: false,
        dialogError: false,
        valid: true,
        dialogForm: false,
        dataUpConfi: {
            applicationCode: null,
            secretKey: null,
            version: null
        },
        indexConfig: -1,
        showKeyForm: false,
        dialogConfirm: false,
        textConfirm: '',
        frameCorrect: false,
        txtEvent: []
    }),
    methods: {
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        getConfigurations() {
            this.smsLoader = 'Obteniendo Datos'
            this.dialogBar = true
            Configurations.getDataConfigurations().then(response => {
                this.dataConfigurations = response
                this.dialogBar = false
            }).catch((error) => {
                this.processError(error)
            })
        },
        openForm(config) {
            this.indexConfig = this.dataConfigurations.indexOf(config)
            this.dataUpConfi = Object.assign({}, config)
            this.dialogForm = true
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                this.dialogForm = true
                this.dialogError = false
                this.dialogConfirm = false
            }
        },
        closeForm() {
            this.$refs.formconfigurations.resetValidation()
            this.dialogForm = false
            this.dataUpConfi = {
                applicationCode: null,
                secretKey: null,
                version: null
            }
            this.indexConfig = -1
            this.dialogBar = false
        },
        validateField(fact) {
            if (!this.emptyField(fact) && !this.withSpaces(fact)) { return true }
            else { return false }
        },
        validateData() {
            var validate = true
            if (!this.$refs.formconfigurations.validate()) { validate = false }
            return validate
        },
        confirUpConfi() {
            this.dialogForm = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                this.textConfirm = '¿Está seguro de editar los datos de las configuraciones?'
                this.dialogConfirm = true
            }
        },
        showEvent(txt) {
            this.txtEvent = txt
            this.frameCorrect = true
            setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        performOperation() {
            this.dialogConfirm = false
            this.smsLoader = 'Realizando Operación'
            this.dialogBar = true
            Configurations.updateConfigurations(this.dataUpConfi).then(response => {
                this.dataConfigurations = [response]
                this.showEvent(['OERACIÓN EXITOSA!',
                                'Los datos de las configuraciones fueron editados'])
                this.closeForm()
            }).catch((error) => {
                this.processError(error)
            })
        }
    },
    mounted() {
        this.getConfigurations()
    }
}
</script>