const domain = process.env.VUE_APP_ROOT_API
const domainRazer = 'https://api.mol.com/pinstore/'
const API_LOCAL = {
    User: domain + 'user',
    Product: domain + 'products',
    Currency: domain + 'currency',
    Configuration: domain + 'configurations',
    Purchase: domain + 'purchase',
    Pin: domain + 'pin'
}
const API_RAZER_GOLD = {
    PRODUCTS: domainRazer + 'product',
    PURCHASEINITIATION: domainRazer + 'purchaseinitiation'
}
export {
    API_LOCAL,
    API_RAZER_GOLD
}
