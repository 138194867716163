<template>
    <v-dialog persistent :value="dialog" max-width="380">
        <v-card>
            <div class="mb-2 pt-3 d-flex justify-center">
                <v-icon x-large class="icono" color="blue">mdi-information</v-icon>
            </div>
            <v-card-text class="pa-0 pl-2 pr-2">
                <h1 style="text-align:center" class="subtitle-1">{{titleSms}}</h1>
            </v-card-text>
            <v-card-actions class="justify-center pb-3">
                <v-btn small color="red" class="mr-2" style="color:white" @click="$emit('click')">NO</v-btn>
                <v-btn small color="green" style="color:white" @click="$emit('clicksi')">SI</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        dialog: Boolean,
        titleSms: String
    }
}
</script>
<style scoped>
.icono {
    animation-duration: 600ms;
    animation-name: slidein;
}
@keyframes slidein {
    from {
        transform: rotate(360deg);
        opacity: 0;
    }
    to {
        transform: rotate(0);
        opacity: 1;
    }
}
</style>