<template>
    <div class="pt-9 home">
        <div style="padding:12px">
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">NOMBRE:</h1>
                <p class="text__p--primary">{{user.nombre}}</p>
            </div>
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">DIRECCIÓN:</h1>
                <p class="text__p--primary">{{user.direccion}}</p>
            </div>
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">CI:</h1>
                <p v-if="user.ci!=null" class="text__p--primary">{{user.ci}}</p>
                <p v-else class="text__p--primary">-----</p>
            </div>
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">CELULAR:</h1>
                <p v-if="user.celular!=null" class="text__p--primary">{{user.celular}}</p>
                <p v-else class="text__p--primary">-----</p>
            </div>
            <div class="d-flex pa-1">
                <h1 class="text__h1--primary mr-1">USERNAME:</h1>
                <p v-if="user.celular!=null" class="text__p--primary">{{user.username}}</p>
                <p v-else class="text__p--primary">-----</p>
            </div>
            <v-btn outlined color="#063b16" @click="dialogForm=true" small class="mt-2">CAMBIAR CUENTA</v-btn>
        </div>
        <v-dialog max-width="500px" persistent v-model="dialogForm">
            <v-card>
                <CardTitle title="Cambiar Cuenta" @click="closeForm()" />
                <v-card-text>
                    <v-form
                            class="mt-2"
                            v-on:submit.prevent="getIn()"
                            ref="formperfil"
                            v-model="valid"
                            lazy-validation>
                        <TextFieldForm
                            class="mt-4 mb-3"
                            v-model="dataUser.username"
                            :clearable="true"
                            label="Username"
                            :counter="20"
                            :rules="[v => validateField(v) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos',
                                v => (v+'').length>=10 || 'El username debe ser igual o mayor a 10 dígitos']"
                        />
                        <TextFieldForm
                            class="mb-3"
                            v-model="dataUser.contrasenia"
                            :clearable="true"
                            :type="showPassword ? 'text' : 'password'"
                            label="Contraseña"
                            :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @clicksee="showPassword = !showPassword"
                            @search="getIn()"
                            :rules="[v => validateField(v) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=20 || 'La contraseña no debe exeder los 20 dígitos',
                                v => (v+'').length>=10 || 'La contraseña debe ser igual o mayor a 10 dígitos']"
                        />
                        <TextFieldForm
                            v-model="dataUser.repitcontrasenia"
                            :clearable="true"
                            :type="showPassword ? 'text' : 'password'"
                            label="Repita Contraseña"
                            :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @clicksee="showPassword = !showPassword"
                            @search="getIn()"
                            :rules="[v => validateField(v) || 'El dato es requerido y no debe contener espacios',
                                v => samePasswords(v) || 'Las contraseñas no coinciden, revise los datos',
                                v => (v+'').length<=20 || 'La contraseña no debe exeder los 20 dígitos',
                                v => (v+'').length>=10 || 'La contraseña debe ser igual o mayor a 10 dígitos']"
                        />
                    </v-form>
                </v-card-text>
                <CardActions accion="Actualizar" @click="updateUser() "/>
            </v-card>
        </v-dialog>
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <SmsConfirm
                :titleSms="textConfirm"
                :dialog="dialogConfirm"
                @clicksi="performOperation()"
                @click="backForm()"
        />
        <LoaderBar :dialog="dialogBar" message="Obteniendo Datos" />
        <ChangeAccount
                title="Operación Exitosa"
                subtitle="Será dirigido a la vista Login, para acceder con sus credenciales actualizadas"
                @click="backLogin()"
                :dialog="dialogChangeAccount"
        />
    </div>
</template>
<script>
import {
    CardTitle,
    CardActions,
    SmsError,
    SmsConfirm,
    LoaderBar,
    ChangeAccount
} from '../../components/organisms'
import { TextFieldForm } from '../../components/molecules'
import { rules, responseCase } from '../../mixins'
import { User } from '../../models'
export default {
    mixins: [rules, responseCase],
    components: {
        CardTitle,
        CardActions,
        TextFieldForm,
        SmsError,
        SmsConfirm,
        LoaderBar,
        ChangeAccount
    },
    data: () => ({
        dialogChangeAccount: false,
        dialogBar: false,
        noAccess: false,
        textConfirm: '',
        dialogConfirm: false,
        dialogError: false,
        smsError: [],
        actionError: false,
        showPassword: false,
        user: {},
        dialogForm: false,
        dataUser: {
            id: JSON.parse(localStorage.getItem('empresauser')).id,
            username: JSON.parse(localStorage.getItem('empresauser')).username,
            contrasenia: null,
            repitcontrasenia: null
        },
        valid: true
    }),
    methods: {
        getValidUser() {
            this.dialogBar = true
            User.validAccess().then(() => {
                this.dialogBar = false
                this.user = JSON.parse(localStorage.getItem('empresauser'))
            }).catch((error) => {
                this.processError(error)
            })
        },
        backLogin() {
            localStorage.removeItem('changeuser')
            localStorage.removeItem('empresauser')
            this.$router.replace('/login')
        },
        validateField(fact) {
            if (!this.emptyField(fact) && !this.withSpaces(fact)) { return true }
            else { return false }
        },
        samePasswords(fact) {
            if (fact !== this.dataUser.contrasenia) { return false }
            else { return true }
        },
        validateData() {
            var validate = true
            if (!this.$refs.formperfil.validate()) { validate = false }
            return validate
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                this.dialogForm = true
                this.dialogError = false
            }
        },
        backForm() {
            this.dialogForm = true
            this.dialogConfirm = false
        },
        closeForm() {
            this.$refs.formperfil.resetValidation()
            this.dialogForm = false
            this.dataUser = {
                id: JSON.parse(localStorage.getItem('empresauser')).id,
                username: JSON.parse(localStorage.getItem('empresauser')).username,
                contrasenia: null,
                repitcontrasenia: null
            }
        },
        updateUser() {
            this.dialogForm = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                this.textConfirm = '¿Está seguro de cambiar sus credenciales de acceso?'
                this.dialogConfirm = true
            }
        },
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        showEvent(text) {
            this.textSuccess = text
            this.smsCorrecto = true
            setTimeout(() => {
                this.smsCorrecto = false
            }, this.tiempoalerta)
        },
        performOperation() {
            this.dialogConfirm = false
            this.dialogBar = true
            User.updateUserPass(this.dataUser).then(response => {
                var changeUser = 'si'
                localStorage.setItem('changeuser', JSON.stringify(changeUser))
                this.dialogBar = false
                this.dialogChangeAccount = true
            }).catch((error) => {
                this.processError(error)
            })
        }
    },
    mounted() {
        this.getValidUser()
    }
}
</script>
