export default {
    methods: {
        animacionScroll(duration) {
            var start = window.pageYOffset
            var startTime = 'now' in window.performance ? performance.now() : new Date().getTime()
            if ('requestAnimationFrame' in window === false) {
                window.scroll(0, 0)
                return
            }
            function scroll () {
                var now = 'now' in window.performance ? performance.now() : new Date().getTime()
                var time = Math.min(1, ((now - startTime) / duration))
                var timeFunction = time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time
                window.scroll(0, Math.ceil((timeFunction * (0 - start)) + start))
                if (window.pageYOffset === 0) {
                return
                }
                requestAnimationFrame(scroll)
            }
            scroll()
        },
        eventAnimationMouse(scrolly) {
            if (scrolly <= 100) {
                return 0
            } else {
                if (scrolly <= 400) {
                    return 300
                } else {
                    if (scrolly <= 700) {
                        return 420
                    } else {
                        return 540
                    }
                }
            }
        }
    }
}