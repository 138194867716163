export default {
    namespaced: true,
    state: {
        header: ''
    },
    mutations: {
        addHeader(state, payload) {
            state.header = payload
        }
    }
}
