import Vue from 'vue'
import VueRouter from 'vue-router'

import {
    Users,
    Home,
    Perfil,
    Products,
    Currency,
    Configurations,
    Purchases,
    Cart,
    Faileds
} from '../views/admin'

Vue.use(VueRouter)

export default new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/admin',
            redirect: { name: 'home'}
        },
        {
            path: '/admin',
            component: () => import('../views/admin/AppAdmin'),
            children: [
                {
                    path: '/admin/home',
                    name: 'home',
                    component: Home
                },
                {
                    path: '/admin/users',
                    name: 'users',
                    component: Users
                },
                {
                    path: '/admin/perfil',
                    name: 'perfil',
                    component: Perfil
                },
                {
                    path: '/admin/products',
                    name: 'products',
                    component: Products
                },
                {
                    path: '/admin/currency',
                    name: 'currency',
                    component: Currency
                },
                {
                    path: '/admin/configurations',
                    name: 'configurations',
                    component: Configurations
                },
                {
                    path: '/admin/purchases',
                    name: 'purchases',
                    component: Purchases
                },
                {
                    path: '/admin/faileds',
                    name: 'faileds',
                    component: Faileds
                },
                {
                    path: '/admin/cart',
                    name: 'cart',
                    component: Cart
                }
            ]
        },
        {
            path: '/login',
            component: () => import('../views/login/Login')
        }
    ]
})
