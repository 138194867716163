<template>
    <div class="card-currency d-block">
        <div class="d-flex">
            <h1 class="text__card--title">CUPÓN:</h1>
            <h2 class="text__card--subtitle ml-1">{{num}}</h2>
        </div>
        <div class="d-flex">
            <h2>
                <div class="ml-3 cart-currency__cuadro" :style="calculateDateBool()"></div>
            </h2>
            <h1 class="text__card--subtitle ml-1">{{calculateDateSms()}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">FECHA DE EXPIRACIÓN:</h1>
            <h2 class="text__card--subtitle ml-1">{{coupon.expiryDateFormat}}</h2>
        </div>
        <div>
            <h1 class="text__card--title">SERIALES:</h1>
            <h2
                class="text__card--subtitle ml-3"
                v-for="serial in coupon.serials"
                :key="serial.serial"
            >{{serial.serial}}</h2>
        </div>
        <div>
            <h1 class="text__card--title">PINES:</h1>
            <div
                class="d-flex align-center mt-1"
                v-for="(pin, index) in coupon.pins"
                :key="pin.pin"
            >
                <v-btn
                    depressed
                    fab
                    x-small
                    :color="colorBtnPin(pin)"
                    @click="eSendParameters(pin, index)"
                ><!--@click="$emit('click', pin)"-->
                    <v-icon small color="white">mdi-cart</v-icon>
                </v-btn>
                <h2 class="text__card--subtitle ml-2" :style="colorPin(pin)">{{pin.pin}}</h2>
                <h2 class="text__card--subtitle ml-2" style="color:green" v-if="pin.sold==0">- Sin Vender</h2>
                <h2 class="text__card--subtitle ml-2" style="color:red" v-else>- Vendido</h2>
            </div>
        </div>
    </div>
</template>
<script>
import moment from '../../plugins/moment'
export default {
    props:{
        coupon: {},
        num: Number
    },
    methods: {
        dateCurrent() {
            let today = new Date()
            let dd = today.getDate()
            let mm = today.getMonth() + 1
            let yyyy = today.getFullYear()
            if (dd < 10) { dd = '0' + dd }
            if (mm < 10) { mm = '0' + mm }
            today = yyyy + '-' + mm + '-' + dd
            return today
        },
        calculateDateSms() {
            var dateCoupon = moment(this.coupon.expiryDate)
            var dateCurrent = moment(this.dateCurrent())
            var diff = dateCoupon.diff(dateCurrent, 'days')
            var smsDays = ''
            if (diff > 0) {
                smsDays = 'Faltan ' + diff + ' días para que el cupón expire'
            } else {
                if (diff < 0) {
                    smsDays = 'Cupón expirado hace ' + (diff*-1) + ' días'
                } else {
                    smsDays = 'El cupón expira el día de hoy ' + this.coupon.expiryDateFormat
                }
            }
            return smsDays
        },
        calculateDateBool() {
            var dateCoupon = moment(this.coupon.expiryDate)
            var dateCurrent = moment(this.dateCurrent())
            var diff = dateCoupon.diff(dateCurrent, 'days')
            if (diff >= 0) {
                return 'background: green'
            } else {
                return 'background: red'
            }
        },
        colorPin(pin) {
            if (pin.sold === 0) {
                return 'color: green'
            } else {
                return 'color: red'
            }
        },
        colorBtnPin(pin) {
            if (pin.sold === 0) {
                return 'green'
            } else {
                return 'red'
            }
        },
        eSendParameters(pin, index) {
            let newPing = Object.assign({}, pin);
            newPing.pin = `${newPing.pin} - ${this.coupon.serials[index].serial}`;
            this.$emit('click', newPing);
        }
    }
}
</script>
