export default {
    methods: {
        emptyField(fact) {
            if (fact === null || fact === '') {
                return true
            } else {
                var counter = 0
                var newfact = fact+''
                for(var i = 0; i < newfact.length; i++) {
                    if (newfact[i]===' ') {
                        ++counter
                    }
                }
                if ( counter === newfact.length) {
                    return true
                } else {
                    return false
                }
            }
        },
        withSpaces(fact) {
            var value = false
            var newfact = fact + ''
            for (var i = 0; i < newfact.length; i++) {
                if (newfact[i] === ' ') {
                    value = true
                    break
                }
            }
            return value
        },
        validNumber(fact) {
            if (isNaN(fact)) {
                return false
            } else {
                var datocadena = fact+''
                for(var i = 0; i < datocadena.length; i++) {
                    if (datocadena[i] == '.') {
                        return false
                    }
                }
                return true
            }
        }
    }
}