export default {
    namespaced: true,
    state: {
        pins: []
    },
    mutations: {
        addPin(state, payload) {
            state.pins.push(payload)
        },
        kickPin(state, payload) {
            state.pins.splice(payload, 1)
        },
        clearListPins(state) {
            state.pins = []
        }
    }
}