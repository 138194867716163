<template>
    <div class="card-currency d-block">
        <div class="d-flex">
            <h1 class="text__card--title">STOCK PRODUCTO:</h1>
            <h2 class="text__card--subtitle ml-1" v-if="product.isStockAvailable!=0">
                Con Stock
                <div class="ml-2 cart-currency__cuadro" style="background: green"></div>
            </h2>
            <h2 class="text__card--subtitle ml-1" v-else>
                Sin Stock
                <div class="ml-2 cart-currency__cuadro" style="background: red"></div>
            </h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">NOMBRE:</h1>
            <h2 class="text__card--subtitle ml-1">{{product.productName}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">COMISIÓN:</h1>
            <h2 class="text__card--subtitle ml-1">{{product.commission}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">PRECIO UNITARIO:</h1>
            <h2 class="text__card--subtitle ml-1">{{product.unitPrice }}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">PRECIO A PAGAR:</h1>
            <h2 class="text__card--subtitle ml-1">{{product.payablePrice }}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">DIVISA:</h1>
            <h2 class="text__card--subtitle ml-1">{{product.currency }}</h2>
        </div>
        <div class="cart-currency__line"></div>
        <div class="d-flex justify-center">
            <OptionButton
                :outlined="true"
                icono="mdi-file-table-outline"
                coloricono="#06143bb6"
                colorbtn="#06143bb6"
                :small="true"
                class="ma-1"
                @click="$emit('clickdetail', product)"
            />
            <v-btn
                :ripple="false"
                depressed
                small
                class="ma-1"
                color="#d2e6d3"
                outlined
                v-for="fact in quantity" :key="fact"
                @click="$emit('clickquantity', getValue(fact))"
            >
                <h1 class="pr-0 text__card--title" style="color:#56a55a">{{fact}}</h1>
            </v-btn>
            <v-menu
                transition="slide-x-transition"
                :rounded="true"
                offset-y
                left
                class="mt-2"
            >
                <template v-slot:activator="{ attrs, on }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :ripple="false"
                        depressed
                        small
                        class="ma-1"
                        color="#d2e6d3"
                        outlined
                    >
                        <h1 class="pr-0" style="color:#56a55a">...</h1>
                    </v-btn>
                </template>
                <v-list class="pa-0 pt-1 pr-1 pl-1">
                    <v-list-item
                            style="min-height:auto !important"
                            v-for="(quantity, index) in quantityListTwo"
                            :key="index"
                            class="mb-1 pa-0"
                        >
                        <v-btn
                            :ripple="false"
                            depressed
                            small
                            color="#d2e6d3"
                            outlined
                            @click="$emit('clickquantity', getValue(quantity))"
                        >
                            <h1 class="pr-0" style="font-size:16px; color:#56a55a;">{{quantity}}</h1>
                        </v-btn>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </div>
</template>
<script>
import { OptionButton } from '../organisms'
export default {
    components: {
        OptionButton
    },
    props:{
        product: {}
    },
    data: () => ({
        quantity: [1, 2, 3],
        quantityListTwo: [4,5,6,7,8,9,10]
    }),
    methods: {
        getValue(fact) {
            var information = {
                quantity: fact,
                product: this.product
            }
            return information
        }
    }
}
</script>
