<template>
    <div class="pt-10 home">
        <div class="d-flex justify-center">
            <div class="home__message">
                <div class="home__title pb-3 d-flex justify-center align-center">
                    <img height="30px" src="/logos/gatonet.png" />
                    <h1 class="text__h1--secundary ml-2">Bienvenido a GATONET</h1>
                </div>
                <div class="home__containerInfo">
                    <h1 class="text__h1--primary">RECOMENDACIONES</h1>
                    <div class="d-flex">
                        <v-icon color="#071849" class="mr-2">mdi-chevron-right-circle</v-icon>
                        <p class="text__p--primary">
                            Si ingresa desde un dispositivo que no es de su propiedad, porfavor
                            cierre sesión despues de utilizar el sistema.
                        </p>
                    </div>
                    <div class="d-flex">
                        <v-icon color="#071849" class="mr-2">mdi-chevron-right-circle</v-icon>
                        <p class="text__p--primary">
                            Para matener seguro el sistema, cambie su contraseña de forma regular.
                        </p>
                    </div>
                    <div class="d-flex">
                        <v-icon color="#071849" class="mr-2">mdi-chevron-right-circle</v-icon>
                        <p class="text__p--primary">
                            Si visualiza un mensaje de error por conección de error en el servidor, refresque la página
                            y verifique la información que proceso.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center">
            <div class="home__container-buttons d-flex justify-center flex-wrap">
                <Button
                    :outlined="true"
                    :sinsombra="true"
                    class="ma-1"
                    v-for="option in listMenu"
                    :key="option.id"
                    :to="option.router"
                    style="width:174px"
                    colorbtn="#06143bb6"
                    :small="true"
                >
                    <v-icon small color="#06143bb6" class="mr-1">
                        {{option.icon}}
                    </v-icon>
                    {{option.title}}
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import { Button } from '../../components/molecules'
export default {
    components: {
        Button
    },
    data: () => ({
        listMenu: [
            {
                title: 'Mi perfil',
                router: 'perfil',
                icon: 'mdi-account-circle'
            },
            {
                title: 'Productos',
                router: 'products',
                icon: 'mdi-format-list-bulleted-square'
            },
            {
                title: 'Compras',
                router: 'purchases',
                icon: 'mdi-cash'
            },
            {
                title: 'Compras Fallidas',
                router: 'faileds',
                icon: 'mdi-cash-remove'
            },
            {
                title: 'Usuarios',
                router: 'users',
                icon: 'mdi-account-lock',
            },
            {
                title: 'Paises',
                router: 'currency',
                icon: 'mdi-home-map-marker',
            },
            {
                title: 'Configuraciones',
                router: 'configurations',
                icon: 'mdi-cog'
            }
        ]
    })
}
</script>
