import axios from 'axios'
import { API_LOCAL } from '../plugins/apiUrls'
import { LoginStore } from '../store/moduls'

export default class User {
    constructor(
        id = null,
        nombre = null,
        ci = null,
        direccion = null,
        celular = null,
        username = null,
        contrasenia = null,
        repitcontrasenia = null,
        categoria = 0
    ) {
        this.id = id
        this.nombre = nombre
        this.ci = ci
        this.direccion = direccion
        this.celular = celular
        this.username = username
        this.contrasenia = contrasenia
        this.repitcontrasenia = repitcontrasenia
        this.categoria = categoria
    }
    static authenticate(account) {
        return axios.post(API_LOCAL.User + '/login', account).then(
            response => {
                return response.data
            }
        )
    }
    static closedSession() {
        return axios.post(API_LOCAL.User + '/logout', '', {headers: LoginStore.state.header}).then(response => {
            return response.data
        })
    }
    static updateUserPass(parameters) {
        return axios.put(API_LOCAL.User + '/change-user', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static getUsers(id, search) {
        return axios.get(API_LOCAL.User + '/list?id=' + id + '&search=' + search, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static validUser(param, id, username) {
        return axios.get(API_LOCAL.User + '/validuser?username=' + username + '&param=' + param + '&id=' + id, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static addUser(parameters) {
        return axios.post(API_LOCAL.User + '/add', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static updateDataUser(parameters) {
        return axios.put(API_LOCAL.User + '/update', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static enableDisable(id) {
        return axios.patch(API_LOCAL.User + '/enable-disable/' + id, '', {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static validAccess() {
        return axios.get(API_LOCAL.User + '/valid-access', {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
}