<template>
    <div class="pt-10 home">
        <div class="products home__title pa-2">
            <h1 class="text__h1--secundary">PRODUCTOS</h1>
            <v-spacer></v-spacer>
            <div class="productsHeader">
                <Search
                    class="productsHeader__search"
                    v-model="txtSearch"
                    @search="searchProduct()"
                />
                <div class="d-flex productsHeader__select">
                    <Select
                        v-model="searchPais"
                        :detallesOcultos="true"
                        backgroundColor="white"
                        :enLinea="true"
                        :items="listDataCurrency"
                        itemText="nombrePais"
                        itemValue="abreviacionPais"
                        label="PAÍS"
                        @change="searchSelectPais"
                    />
                    <OptionButton
                            class="ml-1"
                            @click="dialogPurchaseDirect = true"
                            icono="mdi-account-cash"
                            :redondo="true"
                            :small="true"
                            coloricono="white"
                            colorbtn="green"
                    />
                    <OptionButton
                            class="ml-1"
                            @click="refleshData()"
                            icono="mdi-refresh"
                            :redondo="true"
                            :small="true"
                            coloricono="white"
                            colorbtn="blue"
                    />
                </div>
            </div>
        </div>
        <div style="width:100%; overflow:hidden;">
            <div class="users-header__line"></div>
        </div>
        <LoaderCircle
            :dialog="dialogLoaderCircle"
            :message="smsLoader"
            @click="dialogLoaderCircle=false"
        />
        <div class="d-flex justify-center flex-wrap">
            <CardProducts
                v-for="product in listProductos" :key="product.id"
                :product="product"
                class="ma-2"
                @clickdetail="seeDetail"
                @clickquantity="startRequest"
            />
        </div>
        <Pagination
            v-if="listProductos.length>0 && !dialogLoaderCircle"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            class="mt-2"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="changePage(false)"
            @clickgo="changePage(true)"
        />
        <TitleNoData class="mt-3" v-if="listProductos.length==0 && !dialogLoaderCircle" title="SIN REGISTROS" />
        <v-dialog max-width="500px" persistent v-model="dialogDetail">
            <v-card color="white">
                <CardTitle title="Información" @click="dialogDetail=false" />
                <v-card-text style="color:rgba(0,0,0,0.92)" class="pa-2">
                    <div class="d-flex">
                        <h1 class="text__card--title">NOMBRE:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.productName}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">DESCRIPCIÓN:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.productDescription}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">STOCK PRODUCTO:</h1>
                        <h2 class="text__card--subtitle ml-1" v-if="product.isStockAvailable!=0">
                            Con Stock
                            <div class="ml-2 cart-currency__cuadro" style="background: green"></div>
                        </h2>
                        <h2 v-else class="text__card--subtitle ml-1">
                            Sin Stock
                            <div class="ml-2 cart-currency__cuadro" style="background: red"></div>
                        </h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">CÓDIGO DEL PRODUCTO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.productCode}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">COMISIÓN:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.commission}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">PRECIO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.price}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">PRECIO UNITARIO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.unitPrice}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">PRECIO A PAGAR:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.payablePrice}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">DIVISA:</h1>
                        <h2 class="text__card--subtitle ml-1">{{product.currency}}</h2>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500px" persistent v-model="dialogDetailPinPurchse">
            <v-card color="white">
                <CardTitle title="Iniciar Pedido" @click="closeInitialPinPurchase()" />
                <v-card-text style="color:rgba(0,0,0,0.92)" class="pa-2">
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.productName" />
                        <h1 class="text__card--title">PRODUCTO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pinPurchase.productName}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.applicationCode" />
                        <h1 class="text__card--title">COD. DE LA APLICACIÓN:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pinPurchase.applicationCode}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.version" />
                        <h1 class="text__card--title">VERSIÓN:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pinPurchase.version}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.referenceId" />
                        <h1 class="text__card--title">ID DE REFERENCIA:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pinPurchase.referenceId}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.productCode" />
                        <h1 class="text__card--title">CÓDIGO DEL PRODUCTO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pinPurchase.productCode}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.quantity+''" />
                        <h1 class="text__card--title">CANTIDAD:</h1>
                        <h2 class="text__card--subtitle ml-1">{{pinPurchase.quantity}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="pinPurchase.signature" />
                        <h1 class="text__card--title">FIRMA:</h1>
                    </div>
                    <h2 class="text__card--subtitle ml-5">{{pinPurchase.signature}}</h2>
                    <v-form
                            class="mt-2"
                            v-on:submit.prevent="makeRequest()"
                            ref="formpinpurchase"
                            v-model="valid"
                            lazy-validation>
                        <TextFieldForm
                            class="mt-3"
                            v-model="pinPurchase.consumerEmail"
                            :clearable="true"
                            label="E-mail"
                            :counter="100"
                            :rules="[v => validateField(v) || 'El E-mail no debe contener espacios',
                                v => (v+'').length<=100 || 'El E-mail no debe exeder los 100 dígitos']"
                        />
                        <TextFieldForm
                            class="mt-2"
                            v-model="pinPurchase.consumerIp"
                            :clearable="true"
                            label="IP"
                            :counter="20"
                            :rules="[v => validateField(v) || 'La IP no debe contener espacios',
                                v => (v+'').length<=20 || 'La IP no debe superar los 20 dígitos']"
                        />
                        <TextFieldForm
                            class="mt-2"
                            v-model="pinPurchase.consumerCountryCode"
                            :clearable="true"
                            label="Código del  país (Ejm: MY)"
                            :counter="2"
                            :rules="[v => validateField(v) || 'El código del país no debe contener espacios',
                                v => (v+'').length<=2 || 'El código del país no debe superar los 2 dígitos']"
                        />
                    </v-form>
                </v-card-text>
                <CardActions accion="REALIZAR PEDIDO" @click="makeRequest()"/>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500px" persistent v-model="dialogBuyPin">
            <v-card color="white">
                <CardTitle title="Realizar Compra" @click="closeBuyPins()" />
                <v-card-text style="color:rgba(0,0,0,0.92)" class="pa-2">
                    <div class="d-flex">
                        <h1 class="text__card--title">PRODUCTO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{dataPurchase.productName}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">CANTIDAD:</h1>
                        <h2 class="text__card--subtitle ml-1">{{dataPurchase.quantity}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="validBuyPins ? '00' : ''" />
                        <h2 class="text__card--subtitle ml-1">{{txtBuyPins}}</h2>
                    </div>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="dataPurchase.validatedToken" />
                        <h1 class="text__card--title">TOKEN:</h1>
                    </div>
                    <h2 class="text__card--subtitle" v-if="validBuyPins">{{dataPurchase.validatedToken}}</h2>
                    <h2 class="text__card--subtitle" v-else>NO ENVIADO</h2>
                </v-card-text>
                <CardActions :disabled="!validBuyPins" accion="COMPRAR" @click="confirmBuyPins()"/>
            </v-card>
        </v-dialog>
        <v-dialog
            width="400"
            v-model="dialogPurchaseDirect"
            persistent
        >
            <v-card>
                <CardTitle title="Compra Directa - Reenvío" @click="closeFormPurchaseDirect()" />
                <div class="pa-2">
                    <h1 class="text__card--subtitle">Ingrese los datos correctos para realizar el reenvio de su compra.</h1>
                    <h1 class="text__card--subtitle">Al presionar en el botón se enviará su compra.</h1>
                    <v-form
                        class="mt-2"
                        v-on:submit.prevent="sendPurchaseDirect()"
                        ref="formpurchasedirect"
                        v-model="validPurchase"
                        lazy-validation
                    >
                        <TextFieldForm
                            class="mt-4"
                            v-model="idReference"
                            :clearable="true"
                            label="ID Referencia Compra"
                            :counter="100"
                            :rules="[v => !emptyField(v) || 'El dato es requerido']"
                        />
                        <TextFieldForm
                            class="mt-2"
                            v-model="signature"
                            :clearable="true"
                            label="Signature"
                            :counter="200"
                            :rules="[v => !emptyField(v) || 'El dato es requerido']"
                        />
                        <TextFieldForm
                            class="mt-2"
                            v-model="token"
                            :clearable="true"
                            label="Token"
                            :counter="200"
                            :rules="[v => !emptyField(v) || 'El dato es requerido']"
                        />
                        <div
                            @click="eOpenSelectProduct()"
                            style="cursor:pointer"
                        >
                            <TextFieldForm
                                class="mt-2"
                                v-model="nameProduct"
                                :disabled="true"
                                label="Producto"
                                :hideDetails="true"
                                :rules="[v => !emptyField(v) || 'El dato es requerido']"
                            />
                        </div>
                        <div class="text-center">
                            <Button
                                class="mt-3"
                                :sinsombra="true"
                                colorbtn="success"
                                @click="sendPurchaseDirect()"
                            >REENVIAR COMPRA</Button>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
        <LoaderBar :dialog="dialogBar" :message="smsLoader" />
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <SmsConfirm
                :titleSms="textConfirm"
                :dialog="dialogConfirm"
                @clicksi="buyPins()"
                @click="backToPinPurchase()"
        />
        <SmsConfirm
                :titleSms="textApiRazer"
                :dialog="dialogConfirmApiRazer"
                @clicksi="performApiRazer()"
                @click="closeConfirmApi()"
        />
        <SmsCorrect
                @click="frameCorrect=false"
                :snakCorrecto="frameCorrect"
                :timeout="4000"
                :text="txtEvent"
                :colorSucess="colorSucess"
        />
        <v-dialog max-width="500px" persistent v-model="dialogFinalPurchase">
            <v-card color="white">
                <v-card-text style="color:rgba(0,0,0,0.92)" class="pa-2">
                    <h1 class="text__card--title">{{titleTransaction}}</h1>
                    <div class="d-flex">
                        <ValidPinPurchaseIcon :stringValue="validTransactionPins ? '00' : ''" />
                        <h2 class="text__card--subtitle ml-1">{{subtitleTransaction}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">PRODUCTO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{transacationPinPurchase.productName}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">CANTIDAD DEL PEDIDO:</h1>
                        <h2 class="text__card--subtitle ml-1">{{transacationPinPurchase.quantity}}</h2>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text__card--title">N° DE CUPONES RECIBIDOS:</h1>
                        <h2
                            class="text__card--subtitle ml-1"
                            v-if="transacationPinPurchase.coupons!=undefined"
                        >{{transacationPinPurchase.coupons.length}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">SERIALES:</h1>
                        <h2 class="text__card--subtitle ml-1">{{textSerials}}</h2>
                    </div>
                    <div class="d-flex">
                        <h1 class="text__card--title">PINES:</h1>
                        <h2 class="text__card--subtitle ml-1">{{textPins}}</h2>
                    </div>
                </v-card-text>
                <CardActions accion="REGISTRAR" @click="registerBuyPins()"/>
            </v-card>
        </v-dialog>
        <v-dialog
            width="400"
            v-model="dialogSelectProduct"
            persistent
        >
            <v-card
                min-height="300"
            >
                <CardTitle title="Seleccione Producto" @click="eCloseSelectProduct()" />
                <div class="pa-2">
                    <Search
                        class="productsHeader__search"
                        style="width: 100%"
                        v-model="searchProductModal"
                        @search="searchProductDirect()"
                    />
                </div>
                <v-divider></v-divider>
                <div class="pa-2 pt-0">
                    <v-card
                        v-for="(product, index) in productsSearch"
                        :key="index"
                        class="mt-2"
                        @click="eSelectProduct(product)"
                    >
                        <div class="d-flex pa-2">
                            <h1 class="text__card--title mr-1">Nombre:</h1>
                            <h1 class="text__card--subtitle">
                                {{product.productName}}
                            </h1>
                        </div>
                    </v-card>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { rules, responseCase, animations } from '../../mixins'
import {
    Products,
    Currency,
    Configurations,
    Purchase
} from '../../models'
import {
    Button
} from "../../components/molecules";
import {
    Search,
    OptionButton,
    LoaderBar,
    SmsError,
    Pagination,
    CardProducts,
    CardTitle,
    ValidPinPurchaseIcon,
    CardActions,
    SmsConfirm,
    SmsCorrect,
    LoaderCircle
} from '../../components/organisms'
import {
    TitleNoData,
    Select,
    TextFieldForm
} from '../../components/molecules'
import md5 from 'js-md5'
export default {
    mixins: [rules, responseCase, animations],
    components: {
        Button,
        Search,
        OptionButton,
        TitleNoData,
        LoaderBar,
        SmsError,
        Pagination,
        CardProducts,
        Select,
        CardTitle,
        ValidPinPurchaseIcon,
        TextFieldForm,
        CardActions,
        SmsConfirm,
        SmsCorrect,
        LoaderCircle
    },
    data: () => ({
        page: 0,
        last_page: 0,
        listProductos: [],
        txtSearch: '',
        smsError: [],
        actionError: false,
        dialogError: false,
        noAccess: false,
        dialogBar: false,
        smsLoader: '',
        listDataCurrency: [
            {
                nombrePais: 'Todos',
                abreviacionPais: ''
            }
        ],
        searchPais: '',
        dataConfigurations: [],
        txtSearchAltern: '',
        dialogDetail: false,
        product: {},
        pinPurchase: {
            productName: '',
            applicationCode: '',
            version: '',
            referenceId: '',
            productCode: '',
            quantity: '',
            signature: '',
            consumerEmail: '',
            consumerIp: '',
            consumerCountryCode: ''
        },
        dialogDetailPinPurchse: false,
        valid: true,
        textConfirm: '',
        dialogConfirm: false,
        accesApiRazer: false,
        productosApi: [],
        textApiRazer: '',
        dialogConfirmApiRazer: false,
        frameCorrect: false,
        txtEvent: [],
        dataPurchase: {},
        dialogBuyPin: false,
        txtBuyPins: '',
        validBuyPins: false,
        transacationPinPurchase: {},
        dialogFinalPurchase: false,
        titleTransaction: '',
        subtitleTransaction: '',
        validTransactionPins: false,
        dialogLoaderCircle: false,
        dialogPurchaseDirect: false,
        signature: null,
        token: null,
        validPurchase: true,
        colorSucess: "success",
        idReference: null,
        productId: 0,
        dialogSelectProduct: false,
        searchProductModal: "",
        productsSearch: [],
        nameProduct: ""
    }),
    computed: {
        textSerials: function() {
            var listFinalSerials = ''
            if (this.transacationPinPurchase.coupons !== undefined) {
                for(var i = 0; i < this.transacationPinPurchase.coupons.length; i++) {
                    var listSerialCoupon = 'Cupon N° ' + (parseInt(i)+1) + ' ( '
                    for(var k = 0; k < this.transacationPinPurchase.coupons[i].serials.length; k++) {
                        if (k !== (this.transacationPinPurchase.coupons[i].serials.length-1)) {
                            listSerialCoupon = '' + listSerialCoupon + this.transacationPinPurchase.coupons[i].serials[k] + ', '
                        } else {
                            listSerialCoupon = '' + listSerialCoupon + this.transacationPinPurchase.coupons[i].serials[k] + ' )'
                        }
                    }
                    if (i !== (this.transacationPinPurchase.coupons.length-1)) {
                        listFinalSerials = '' + listFinalSerials + listSerialCoupon + ', '
                    } else {
                        listFinalSerials = '' + listFinalSerials + listSerialCoupon
                    }
                }
                return listFinalSerials
            } else {
                return 'SIN SERIALES'
            }
        },
        textPins: function() {
            var listFinalPins = ''
            if (this.transacationPinPurchase.coupons !== undefined) {
                for(var i = 0; i < this.transacationPinPurchase.coupons.length; i++) {
                    var listPinCoupon = 'Cupon N° ' + (parseInt(i)+1) + ' ( '
                    for(var k = 0; k < this.transacationPinPurchase.coupons[i].pins.length; k++) {
                        if (k !== (this.transacationPinPurchase.coupons[i].pins.length-1)) {
                            listPinCoupon = '' + listPinCoupon + this.transacationPinPurchase.coupons[i].pins[k] + ', '
                        } else {
                            listPinCoupon = '' + listPinCoupon + this.transacationPinPurchase.coupons[i].pins[k] + ' )'
                        }
                    }
                    if (i !== (this.transacationPinPurchase.coupons.length-1)) {
                        listFinalPins = '' + listFinalPins + listPinCoupon + ', '
                    } else {
                        listFinalPins = '' + listFinalPins + listPinCoupon
                    }
                }
                return listFinalPins
            } else {
                return 'SIN PINs'
            }
        }
    },
    methods: {
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        getRequestInitial() {
            this.smsLoader = 'Obteniendo Datos'
            this.dialogLoaderCircle = true
            const listProductos = Products.getProductsLocal('', '', 1)
            const listCurrency = Currency.getListCurrency('')
            const configurations = Configurations.getDataConfigurations()
            Promise.all([listProductos, listCurrency, configurations]).then(responses => {
                this.listProductos = responses[0].data
                this.page = responses[0].current_page
                this.last_page = responses[0].last_page
                responses[1].forEach(element => {
                    this.listDataCurrency.push(element)
                });
                this.dataConfigurations = responses[2]
                this.dialogLoaderCircle = false
                if (JSON.parse(localStorage.getItem('buy_pins'))) {
                    this.transactionPendient()
                }
            }).catch((error) => {
                this.processError(error)
            })
        },
        getProductsLocal(search, currency, page) {
            this.smsLoader = 'Obteniendo Datos'
            this.dialogLoaderCircle = true
            Products.getProductsLocal(search, currency, page).then(response => {
                this.listProductos = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.dialogLoaderCircle = false
            }).catch((error) => {
                this.processError(error)
            })
        },
        searchSelectPais(e) {
            this.listProductos = []
            if (this.txtSearch !== null) {
                this.getProductsLocal(this.txtSearch, e, 1)
            } else {
                this.getProductsLocal('', e, 1)
            }
        },
        searchProduct() {
            this.listProductos = []
            this.txtSearchAltern = this.txtSearch
            if (this.txtSearch !== null) {
                this.getProductsLocal(this.txtSearch, this.searchPais, 1)
            } else {
                this.getProductsLocal('', this.searchPais, 1)
            }
        },
        changePage(state) {
            var time = this.eventAnimationMouse(window.scrollY)
            this.animacionScroll(time)
            setTimeout(() => {
                this.listProductos = []
                this.txtSearch = this.txtSearchAltern
                if (state) {
                    this.getProductsLocal(this.txtSearchAltern, this.searchPais, ++this.page)
                } else {
                    this.getProductsLocal(this.txtSearchAltern, this.searchPais, --this.page)
                }
            }, time)
        },
        seeDetail(e) {
            this.product = e
            this.dialogDetail = true
        },
        startRequest(e) {
            if (this.dataConfigurations.length > 0) {
                this.pinPurchase.productName = e.product.productName
                this.pinPurchase.applicationCode = this.dataConfigurations[0].applicationCode
                this.pinPurchase.version = this.dataConfigurations[0].version
                var secretKey = this.dataConfigurations[0].secretKey
                var date = new Date().getTime()
                this.pinPurchase.referenceId = '' + JSON.parse(localStorage.getItem('empresauser')).id + date
                this.listDataCurrency.forEach(element => {
                    if (element.abreviacionPais === e.product.currency) {
                        this.pinPurchase.productCode = '' + element.codigoPais + e.product.productCode
                    }
                })
                this.pinPurchase.codeProductOrigin = e.product.productID
                this.pinPurchase.quantity = e.quantity
                var concatenateSignature = '' + this.pinPurchase.applicationCode +
                        this.pinPurchase.productCode + this.pinPurchase.quantity +
                        this.pinPurchase.referenceId + this.pinPurchase.version +
                        secretKey
                var signature = md5(concatenateSignature)
                this.pinPurchase.signature = signature
            }
            this.dialogDetailPinPurchse = true
        },
        closeInitialPinPurchase() {
            this.pinPurchase = {
                productName: '',
                applicationCode: '',
                version: '',
                referenceId: '',
                productCode: '',
                quantity: '',
                consumerEmail: '',
                consumerIp: '',
                consumerCountryCode: ''
            }
            this.dialogDetailPinPurchse = false
        },
        validateField(fact) {
            return !this.withSpaces(fact)
        },
        validateData() {
            var validate = true
            if (!this.$refs.formpinpurchase.validate()) { validate = false }
            return validate
        },
        validateInfoPin() {
            if (this.pinPurchase.applicationCode === '' || this.pinPurchase.version === '' ||
                    this.pinPurchase.referenceId === '' || this.pinPurchase.productCode === '' ||
                    this.pinPurchase.quantity === '' || this.pinPurchase.signature === '') {
                return false
            } else { return true }
        },
        makeRequest() {
            this.dialogDetailPinPurchse = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                if (this.validateInfoPin()) {
                    this.smsLoader = 'Realizando pedido a RAZER GOLD'
                    if (this.pinPurchase.consumerEmail === null) {
                        this.pinPurchase.consumerEmail = ''
                    }
                    if (this.pinPurchase.consumerIp === null) {
                        this.pinPurchase.consumerIp = ''
                    }
                    if (this.pinPurchase.consumerCountryCode === null) {
                        this.pinPurchase.consumerCountryCode = ''
                    }
                    this.dialogBar = true
                    Products.initialPurchase(this.pinPurchase).then(response => {
                        this.dataPurchase = response
                        this.dataPurchase.fkproductID = this.pinPurchase.codeProductOrigin // fk del producto ID
                        this.dataPurchase.productName = this.pinPurchase.productName
                        switch (response.initiationResultCode) {
                            case '00':
                                this.txtBuyPins = 'Petición correcta del pedido, proceda a relizar la compra del producto'
                                this.validBuyPins = true
                                break
                            case '02':
                                this.txtBuyPins = 'No se tiene el stock para el producto seleccionado'
                                this.validBuyPins = false
                                break
                            case '04':
                                this.txtBuyPins = 'Su fondo es insuficiente para la compra del producto'
                                this.validBuyPins = false
                                break
                            case '05':
                                this.txtBuyPins = 'El codigo del producto no es aceptable para su cuenta de comerciante'
                                this.validBuyPins = false
                                break
                            case '06':
                                this.txtBuyPins = 'Está utilizando el mismo ID de referencia de anteriores compras'
                                this.validBuyPins = false
                                break
                            case '07':
                                this.txtBuyPins = 'Es necesario introducir el país y la dirección IP para la compra'
                                this.validBuyPins = false
                                break
                        }
                        this.dialogBar = false
                        this.dialogBuyPin = true
                    }).catch((error) => {
                        this.processErrorApiRazer(error)
                    })
                } else {
                    if (this.pinPurchase.productCode==='') {
                        this.smsError = ['El país de referencia no existe', 'Dirijase a países y registre el país del producto seleccionado']
                        this.actionError = true
                    } else {
                        this.smsError = ['No se cargaron los datos necesarios para la operación', 'Refresque la página e intentelo de nuevo']
                        this.actionError = false
                    }
                    this.dialogError = true
                }
            }
        },
        closeBuyPins() {
            this.closeInitialPinPurchase()
            this.dataPurchase = {}
            this.dialogBuyPin = false
        },
        processErrorApiRazer(error) {
            this.smsError = this.smsErrorResponseApiRazer(error)
            this.actionError = this.actionResponseApiRazer(error)
            this.noAccess = this.autorizationApiRazer(error)
            this.dialogBar = false
            this.dialogError = true
        },
        closeConfirmApi() {
            this.productosApi = []
            this.dialogConfirmApiRazer = false
        },
        showEvent(txt, isError = false) {
            this.txtEvent = txt
            this.frameCorrect = true
            if (isError) this.colorSucess = "error"
            setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        performApiRazer() {
            this.dialogConfirmApiRazer = false
            this.smsLoader = 'Actualizando los productos'
            this.dialogBar = true
            Products.addUpProducts(this.productosApi).then(response => {
                this.listProductos = response.data
                this.page = response.current_page
                this.last_page = response.last_page
                this.txtSearch = ''
                this.searchPais = ''
                this.dialogBar = false
                this.showEvent(['ACTUALIZACIÓN EXITOSA!', 'Todos los productos fueron actualizados'])
            }).catch((error) => {
                this.processError(error)
            })
        },
        refleshData() {
            if (this.dataConfigurations.length > 0) {
                this.accesApiRazer = true
                this.smsLoader = 'Obteniendo los productos de RAZER GOLD'
                this.dialogBar = true
                var concatenateSignature = '' + this.dataConfigurations[0].applicationCode + this.dataConfigurations[0].version + this.dataConfigurations[0].secretKey
                var valSignature = md5(concatenateSignature)
                var parametersPin = {
                    applicationCode: this.dataConfigurations[0].applicationCode,
                    version: this.dataConfigurations[0].version,
                    signature: valSignature
                }
                Products.getProductsApi(parametersPin).then(response => {
                    if (response.responseStatusCode === '00') {
                        this.textApiRazer = '¿Estás seguro de actualizar los productos?'
                        this.productosApi = response
                        this.accesApiRazer = false
                        this.dialogConfirmApiRazer = true
                    } else {
                        this.smsError = ['La lista de productos no se recupero completamente', 'Intentelo nuevamente']
                        this.actionError = true
                        this.accesApiRazer = true
                        this.dialogError = true
                    }
                    this.dialogBar = false
                }).catch((error) => {
                    this.processErrorApiRazer(error)
                })
            } else {
                this.smsError = ['No se tiene las credenciales registradas en las configuraciones']
                this.actionError = true
                this.noAccess = false
                this.dialogBar = false
                this.dialogError = true
                this.accesApiRazer = true
            }
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                if (!this.accesApiRazer) {
                    this.dialogConfirm = false
                    this.dialogDetailPinPurchse = true
                }
                this.dialogError = false
                this.accesApiRazer = false
            }
        },
        confirmBuyPins() {
            this.textConfirm = '¿Está seguro de realizar la compra de ' + this.dataPurchase.quantity + ' (cantidad) ' + this.dataPurchase.productName + '?'
            this.dialogBuyPin = false
            this.dialogConfirm = true
        },
        backToPinPurchase() {
            this.dialogConfirm = false
            this.dialogBuyPin = true
        },
        stateBuyPins(dataPurchase) {
            var state = false
            switch (dataPurchase.purchaseStatusCode) {
                case '00':
                    this.titleTransaction = 'COMPRA EXITOSA'
                    this.subtitleTransaction = 'Para finalizar la operación pulse en registrar para el envío de la transacción'
                    this.transacationPinPurchase.failed = 0
                    this.validTransactionPins = true
                    state = true
                    break
                case '01':
                    this.titleTransaction = 'COMPRA FALLIDA'
                    this.subtitleTransaction = 'El pago no fue completado'
                    this.transacationPinPurchase.failed = 1
                    this.validTransactionPins = false
                    state = false
                    break
                case '02':
                    this.titleTransaction = 'COMPRA LIMITADA'
                    this.subtitleTransaction = `Entrega parcial o nula de los productos por falta de Stock,
                            pulse en registrar para el envío de la transacción`
                    this.transacationPinPurchase.failed = 0
                    this.validTransactionPins = true
                    state = true
                    break
                case '04':
                    this.titleTransaction = 'COMPRA FALLIDA'
                    this.subtitleTransaction = 'Insuficiente saldo en la billetera de RAZER GOLD'
                    this.transacationPinPurchase.failed = 1
                    this.validTransactionPins = false
                    state = false
                    break
                case '99':
                    this.titleTransaction = 'COMPRA FALLIDA'
                    this.subtitleTransaction = 'La compra del producto fue fallida'
                    this.transacationPinPurchase.failed = 1
                    this.validTransactionPins = false
                    state = false
                    break
            }
            return state
        },
        buyPins() {
            this.smsLoader = 'Procesando la compra del producto'
            this.dialogConfirm = false
            this.dialogBar = true
            var concatenateSignature = '' + this.dataConfigurations[0].applicationCode +
                this.dataPurchase.referenceId + this.dataConfigurations[0].version +
                this.dataPurchase.validatedToken + this.dataConfigurations[0].secretKey
            var valSignature = md5(concatenateSignature)
            var parametersBuyPins = {
                applicationCode: this.dataConfigurations[0].applicationCode,
                version: this.dataConfigurations[0].version,
                referenceId: this.dataPurchase.referenceId,
                validatedToken: this.dataPurchase.validatedToken,
                signature: valSignature
            }
            this.accesApiRazer = true
            Products.buyPins(parametersBuyPins).then(response => {
                this.transacationPinPurchase = response
                this.transacationPinPurchase.productName = this.dataPurchase.productName
                this.transacationPinPurchase.fkproductID = this.dataPurchase.fkproductID
                this.transacationPinPurchase.fkuser = JSON.parse(localStorage.getItem('empresauser')).id
                this.validTransactionPins = this.stateBuyPins(response)
                this.dialogBar = false
                this.accesApiRazer = false
                this.dialogFinalPurchase = true
                localStorage.setItem('buy_pins', JSON.stringify(response))
            }).catch((error) => {
                this.processErrorApiRazer(error)
            })
        },
        resetPurchaseValues() {
            this.dataPurchase = {}
            this.pinPurchase = {}
            this.transacationPinPurchase = {}
            this.validTransactionPins = false
        },
        registerBuyPins() {
            this.dialogFinalPurchase = false
            this.smsLoader = 'Realizando Operación'
            this.dialogBar = true
            Purchase.addBuyApiRazer(this.transacationPinPurchase).then(response => {
                localStorage.removeItem('buy_pins')
                this.resetPurchaseValues()
                this.dialogBar = false
                if (this.validTransactionPins) {
                    this.showEvent(['COMPRA EXITOSA FINALIZADA', 'La compra del producto se realizó correctamente'])
                } else {
                    this.showEvent(['REGISTRO CORRECTO', 'La transacción fallida se registró'])
                }
            }).catch((error) => {
                this.processError(error)
            })
        },
        transactionPendient() {
            this.transacationPinPurchase = JSON.parse(localStorage.getItem('buy_pins'))
            this.validTransactionPins =  this.stateBuyPins(JSON.parse(localStorage.getItem('buy_pins')))
            this.dialogFinalPurchase = true
        },
        closeFormPurchaseDirect() {
            this.nameProduct = "";
            this.productId = 0;
            this.signature = this.token = this.idReference = null;
            this.dialogPurchaseDirect = false;
        },
        sendPurchaseDirect() {
            if (!this.$refs.formpurchasedirect.validate())
                this.showEvent(['Operación Cancelada', 'Ingrese los datos'], true);
            else {
                this.dialogPurchaseDirect = false;
                const parameters = {
                    applicationCode: this.dataConfigurations[0].applicationCode,
                    version: this.dataConfigurations[0].version,
                    referenceId: this.idReference,
                    validatedToken: this.token,
                    signature: this.signature
                };
                this.smsLoader = 'Procesando la compra del producto'
                this.dialogBar = true
                this.accesApiRazer = true;
                Products.buyPins(parameters).then(response => {
                    this.signature = this.idReference = this.token = null;
                    this.transacationPinPurchase = response
                    this.transacationPinPurchase.productName = this.dataPurchase.productName
                    this.transacationPinPurchase.fkproductID = this.productId
                    this.transacationPinPurchase.fkuser = JSON.parse(localStorage.getItem('empresauser')).id
                    this.validTransactionPins = this.stateBuyPins(response)
                    this.dialogBar = false
                    this.accesApiRazer = false
                    this.dialogFinalPurchase = true
                    localStorage.setItem('buy_pins', JSON.stringify(response))
                }).catch((error) => {
                    this.processErrorApiRazer(error)
                })
            }
        },
        eOpenSelectProduct() {
            this.dialogSelectProduct = true;
        },
        eCloseSelectProduct() {
            this.productsSearch = [];
            this.searchProductModal = "";
            this.dialogSelectProduct = false;
        },
        searchProductDirect() {
            this.smsLoader = "Obteniendo Datos";
            this.dialogBar = true;
            Products.getProductsLocal(this.searchProductModal, "", 1).then(response => {
                this.productsSearch = response.data;
                this.dialogBar = false;
            }).catch((error) => {
                this.processError(error)
            })
        },
        eSelectProduct(product) {
            this.productId = product.productId;
            this.nameProduct = product.productName;
            this.eCloseSelectProduct();
        }
    },
    mounted() {
        this.getRequestInitial()
    }
}
</script>
