import axios from 'axios'
import { API_LOCAL } from '../plugins/apiUrls'
import { LoginStore } from '../store/moduls'
export default class Currency {
    constructor(
        id = null,
        nombrePais = null,
        abreviacionPais = null,
        codigoPais = null,
        fkuser = null
    ) {
        this.id = id
        this.nombrePais = nombrePais
        this.abreviacionPais = abreviacionPais
        this.codigoPais = codigoPais
        this.fkuser = fkuser
    }
    static getListCurrency(search) {
        return axios.get(API_LOCAL.Currency + '/list?search=' + search, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static addCurrency(parameters) {
        return axios.post(API_LOCAL.Currency + '/add', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static updateCurrency(parameters) {
        return axios.put(API_LOCAL.Currency + '/update', parameters, {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
}