<template>
    <div class="card-currency d-block">
        <div class="d-flex">
            <h1 class="text__card--title">NOMBRE PAÍS:</h1>
            <h2 class="text__card--subtitle ml-1">{{currency.nombrePais}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">ABREVIACION:</h1>
            <h2 class="text__card--subtitle ml-1">{{currency.abreviacionPais}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CÓDIGO PAÍS:</h1>
            <h2 class="text__card--subtitle ml-1">{{currency.codigoPais}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CREADO POR:</h1>
            <h2 class="text__card--subtitle ml-1">{{currency.usuario}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">FECHA:</h1>
            <h2 class="text__card--subtitle ml-1">{{currency.fecha}}</h2>
        </div>
        <div class="cart-currency__line"></div>
        <div class="d-flex justify-center">
            <OptionButton
                :outlined="true"
                icono="mdi-pencil"
                coloricono="#06143bb6"
                colorbtn="#06143bb6"
                :small="true"
                class="ma-1"
                @click="$emit('click')"
            />
        </div>
    </div>
</template>
<script>
import { OptionButton } from '../organisms'
export default {
    components: {
        OptionButton
    },
    props:{
        currency: {}
    }
}
</script>
