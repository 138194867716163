<template>
    <div class="d-flex justify-center">
        <h1 class="titleNoData">{{title}}</h1>
    </div>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>
<style scoped>
    .titleNoData{
        padding: 5px;
        width: 93%;
        background: white;
        color:rgb(8, 27, 66);
        text-align: center;
        border-radius: 5px;
        font-size: 17px;
        box-shadow: 0px 0px 6px rgba(2, 16, 63, 0.253);
    }
    @media (max-width: 500px) {
        .titleNoData{
            font-size: 14px;
        }
    }
</style>