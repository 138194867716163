import axios from 'axios'
import { API_LOCAL } from '../plugins/apiUrls'
import { LoginStore } from '../store/moduls'
export default class Configurations {
    static getDataConfigurations() {
        return axios.get(API_LOCAL.Configuration + '/list', {headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
    static updateConfigurations(parameters) {
        return axios.put(API_LOCAL.Configuration + '/update', parameters,{headers: LoginStore.state.header}).then(
            response => {
                return response.data
            }
        )
    }
}