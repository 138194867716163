<template>
    <div class="pt-10 home">
        <div class="users__containerOptions home__title pa-2">
            <h1 class="text__h1--secundary">PAISES</h1>
            <v-spacer></v-spacer>
            <div class="d-flex users__containerSearch">
                <Search v-model="txtSearch" @search="searchCurrency()" />
                <OptionButton
                        class="ml-1"
                        @click="openForm()"
                        icono="mdi-plus"
                        :redondo="true"
                        :small="true"
                        coloricono="white"
                        colorbtn="#063b16"
                />
            </div>
        </div>
        <div style="width:100%; overflow:hidden;">
            <div class="users-header__line"></div>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <CardCurrency
                v-for="currency in listCurrency" :key="currency.id"
                :currency="currency"
                class="ma-2"
                @click="editCurrency(currency)"
            />
        </div>
        <LoaderCircle
            :dialog="dialogLoaderCircle"
            :message="smsLoader"
            @click="dialogLoaderCircle=false"
        />
        <v-dialog max-width="500px" persistent v-model="dialogForm">
            <v-card>
                <CardTitle :title="titleForm" @click="closeForm()" />
                <v-card-text class="pt-4 pb-0">
                    <v-form
                            v-on:submit.prevent="sendToConfirmation()"
                            ref="formcurrency"
                            v-model="valid"
                            lazy-validation>
                        <TextFieldForm
                            class="mb-2"
                            v-model="dataCurrency.nombrePais"
                            :clearable="true"
                            label="Nombre"
                            :counter="50"
                            :rules="[v => validateField(v, false) || 'El dato es requerido',
                                v => (v+'').length<=50 || 'El nombre no debe exeder los 50 dígitos']"
                        />
                        <TextFieldForm
                            class="mb-2"
                            v-model="dataCurrency.abreviacionPais"
                            :clearable="true"
                            label="Abreviación"
                            :counter="5"
                            :rules="[v => validateField(v, true) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=5 || 'La abreviación no debe exeder los 5 dígitos']"
                        />
                        <TextFieldForm
                            class="mb-2"
                            v-model="dataCurrency.codigoPais"
                            :clearable="true"
                            label="Código"
                            :counter="10"
                            :rules="[v => validateField(v, true) || 'El dato es requerido y no debe contener espacios',
                                v => (v+'').length<=10 || 'El código no debe exeder los 10 dígitos']"
                        />
                    </v-form>
                </v-card-text>
                <CardActions :accion="actionForm" @click="sendToConfirmation() "/>
            </v-card>
        </v-dialog>
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <LoaderBar :dialog="dialogBar" :message="smsLoader" />
        <TitleNoData class="mt-3" v-if="listCurrency.length==0 && !dialogLoaderCircle" title="SIN REGISTROS" />
        <SmsConfirm
                :titleSms="textConfirm"
                :dialog="dialogConfirm"
                @clicksi="performOperation()"
                @click="backFormError()"
        />
        <SmsCorrect
                @click="frameCorrect=false"
                :snakCorrecto="frameCorrect"
                :timeout="4000"
                :text="txtEvent"
        />
    </div>
</template>
<script>
import {
    Search,
    OptionButton,
    SmsError,
    LoaderBar,
    CardCurrency,
    CardTitle,
    CardActions,
    SmsConfirm,
    SmsCorrect,
    LoaderCircle
} from '../../components/organisms'
import { TitleNoData, TextFieldForm } from '../../components/molecules'
import { Currency } from '../../models'
import { responseCase, rules } from '../../mixins'
export default {
    mixins: [responseCase, rules],
    components: {
        Search,
        OptionButton,
        SmsError,
        LoaderBar,
        TitleNoData,
        CardCurrency,
        TextFieldForm,
        CardTitle,
        CardActions,
        SmsConfirm,
        SmsCorrect,
        LoaderCircle
    },
    data: () => ({
        frameCorrect: false,
        txtEvent: [],
        textConfirm: '',
        dialogConfirm: false,
        dialogForm: false,
        actionForm: '',
        titleForm: '',
        valid: true,
        dataCurrency: new Currency(),
        txtSearch: '',
        listCurrency: [],
        dialogError: false,
        smsError: [],
        actionError: false,
        noAccess: false,
        dialogBar: false,
        smsLoader: '',
        indexCurrency: -1,
        dialogLoaderCircle: false
    }),
    methods: {
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        getListCurrency(search) {
            this.smsLoader = 'Obteniendo Datos'
            this.dialogLoaderCircle = true
            Currency.getListCurrency(search).then(response => {
                this.listCurrency = response
                this.dialogLoaderCircle = false
            }).catch((error) => {
                this.processError(error)
            })
        },
        searchCurrency() {
            this.listCurrency = []
            if (this.txtSearch !== null) {
                this.getListCurrency(this.txtSearch)
            } else {
                this.getListCurrency('')
            }
        },
        openForm() {
            this.dataCurrency.fkuser = JSON.parse(localStorage.getItem('empresauser')).id
            this.titleForm = 'Nuevo País'
            this.actionForm = 'Registrar'
            this.dialogForm = true
        },
        closeForm() {
            this.$refs.formcurrency.resetValidation()
            this.dialogForm = false
            this.dataCurrency = new Currency()
            this.indexCurrency = -1
        },
        validateField(fact, state) {
            if (state) {
                if (this.emptyField(fact) || this.withSpaces(fact)) {
                    return false
                }
                return true
            }
            else { return !this.emptyField(fact) }
        },
        validateData() {
            var validate = true
            if (!this.$refs.formcurrency.validate()) { validate = false }
            return validate
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                this.dialogForm = true
                this.dialogError = false
                this.dialogConfirm = false
            }
        },
        editCurrency(currency) {
            this.dataCurrency = Object.assign({}, currency)
            this.indexCurrency = this.listCurrency.indexOf(currency)
            this.titleForm = 'Editar País'
            this.actionForm = 'Editar'
            this.dialogForm = true
        },
        sendToConfirmation() {
            this.dialogForm = false
            if (!this.validateData()) {
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                if (this.indexCurrency !== -1) {
                    this.textConfirm = '¿Está seguro de editar los datos del país: ' + this.dataCurrency.nombrePais + ' ?'
                } else {
                    this.textConfirm = '¿Está seguro de registrar el país: ' + this.dataCurrency.nombrePais + ' ?'
                }
                this.dialogConfirm = true
            }
        },
        showEvent(txt) {
            this.txtEvent = txt
            this.frameCorrect = true
            setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        performOperation() {
            this.dialogConfirm = false
            this.smsLoader = 'Realizando Operación'
            this.dialogBar = true
            if (this.indexCurrency !== -1) {
                Currency.updateCurrency(this.dataCurrency).then(response => {
                    this.listCurrency[this.indexCurrency] = response
                    this.dialogBar = false
                    this.closeForm()
                    this.showEvent(['OERACIÓN EXITOSA!',
                                'Los datos del país: ' + response.nombrePais + ' fueron editados'])
                }).catch((error) => {
                    this.processError(error)
                })
            } else {
                Currency.addCurrency(this.dataCurrency).then(response => {
                    this.dialogBar = false
                    this.txtSearch = response.nombrePais
                    this.listCurrency = [response]
                    this.closeForm()
                    this.showEvent(['OERACIÓN EXITOSA!',
                                'El país: ' + response.nombrePais + ' fue registrado'])
                }).catch((error) => {
                    this.processError(error)
                })
            }
        }
    },
    mounted() {
        this.getListCurrency('')
    }
}
</script>