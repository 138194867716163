<template>
    <v-app>
        <router-view></router-view>
    </v-app>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
    methods: {
        ...mapMutations('LoginStore', ['addHeader']),
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('empresauser'))) {
            if (JSON.parse(localStorage.getItem('changeuser'))) {
                localStorage.removeItem('changeuser')
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                var apitoken = JSON.parse(localStorage.getItem('empresauser')).api_token
                var header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + apitoken,
                }
                this.addHeader(header)
            }
        } else {
            this.$router.replace('/login')
        }
    }
}
</script>
<style src="./sass/main.scss" lang="scss"></style>