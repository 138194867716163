<template>
    <div class="pt-10 home">
        <div class="users__containerOptions home__title pa-2">
            <h1 class="text__h1--secundary">USUARIOS</h1>
            <v-spacer></v-spacer>
            <div class="d-flex users__containerSearch">
                <Search v-model="txtSearchUser" @search="searchUser()" />
                <OptionButton
                        class="ml-1"
                        @click="openForm()"
                        icono="mdi-plus"
                        :redondo="true"
                        :small="true"
                        coloricono="white"
                        colorbtn="#063b16"
                />
            </div>
        </div>
        <div style="width:100%; overflow:hidden;">
            <div class="users-header__line"></div>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <CardUsers
                v-for="user in listUsers" :key="user.id"
                :user="user"
                class="ma-2"
                @click="selectUser"
            />
        </div>
        <LoaderCircle
            :dialog="dialogLoaderCircle"
            :message="smsLoader"
            @click="dialogLoaderCircle=false"
        />
        <TitleNoData class="mt-3" v-if="listUsers.length==0 && !dialogLoaderCircle" title="SIN REGISTROS" />
        <v-dialog max-width="500px" persistent v-model="dialogForm">
            <v-card>
                <CardTitle :title="titleForm" @click="closeForm()" />
                <v-card-text class="pt-4 pb-0">
                    <v-form
                            v-on:submit.prevent="sendToConfirmation()"
                            ref="formuser"
                            v-model="valid"
                            lazy-validation>
                        <div v-if="parametroOpcion!=4">
                            <Select
                                v-model="dataUser.categoria"
                                :items="listCategory"
                                itemText="categoria"
                                itemValue="valor"
                                label="Categoría"
                            />
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.nombre"
                                :clearable="true"
                                label="Nombre Completo"
                                :counter="50"
                                :rules="[v => validateField(v, false) || 'El dato es requerido',
                                    v => (v+'').length<=50 || 'El nombre no debe exeder los 50 dígitos']"
                            />
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.ci"
                                :clearable="true"
                                label="CI"
                                :counter="10"
                                :rules="[v => validateField(v, true) || 'El dato es requerido y debe ser un número y no contener espacios',
                                    v => (v+'').length<=10 || 'El CI no debe exeder los 10 dígitos']"
                            />
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.direccion"
                                :clearable="true"
                                label="Dirección"
                                :counter="50"
                                :rules="[v => validateField(v, false) || 'El dato es requerido',
                                    v => (v+'').length<=50 || 'El nombre no debe exeder los 50 dígitos']"
                            />
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.celular"
                                :clearable="true"
                                label="Celular"
                                :counter="8"
                                :rules="[v => validateField(v, true) || 'El celular debe ser un número y no contener espacios',
                                    v => (v+'').length<=8 || 'El celular no debe exeder los 8 dígitos']"
                            />
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.username"
                                :clearable="true"
                                label="Username"
                                :counter="20"
                                :rules="[v => validateFieldUser(v) || 'El dato es requerido y no debe contener espacios',
                                    v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos',
                                    v => (v+'').length>=10 || 'El username debe ser igual o mayor a 10 dígitos']"
                            />
                        </div>
                        <div v-if="parametroOpcion!=3">
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.contrasenia"
                                :clearable="true"
                                :type="showPassword ? 'text' : 'password'"
                                label="Contraseña"
                                :counter="20"
                                :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @clicksee="showPassword = !showPassword"
                                @search="sendToConfirmation()"
                                :rules="[v => validateFieldUser(v) || 'El dato es requerido y no debe contener espacios',
                                    v => (v+'').length<=20 || 'La contraseña no debe exeder los 20 dígitos',
                                    v => (v+'').length>=10 || 'La contraseña debe ser igual o mayor a 10 dígitos']"
                            />
                            <TextFieldForm
                                class="mb-2"
                                v-model="dataUser.repitcontrasenia"
                                :clearable="true"
                                :type="showPassword ? 'text' : 'password'"
                                label="Repita Contraseña"
                                :counter="20"
                                :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @clicksee="showPassword = !showPassword"
                                @search="sendToConfirmation()"
                                :rules="[v => validateFieldUser(v) || 'El dato es requerido y no debe contener espacios',
                                    v => samePasswords(v) || 'Las contraseñas no coinciden, revise los datos',
                                    v => (v+'').length<=20 || 'La contraseña no debe exeder los 20 dígitos',
                                    v => (v+'').length>=10 || 'La contraseña debe ser igual o mayor a 10 dígitos']"
                            />
                        </div>
                    </v-form>
                </v-card-text>
                <CardActions :loading="loadingBtnActions" :accion="actionForm" @click="sendToConfirmation() "/>
            </v-card>
        </v-dialog>
        <SmsError
            :dialog="dialogError"
            :smsError="smsError"
            :accion="actionError"
            @click="backFormError()"
        />
        <SmsConfirm
                :titleSms="textConfirm"
                :dialog="dialogConfirm"
                @clicksi="performOperation()"
                @click="backFormConfirmation()"
        />
        <LoaderBar :dialog="dialogBar" :message="smsLoader" />
        <SmsCorrect
                @click="frameCorrect=false"
                :snakCorrecto="frameCorrect"
                :timeout="4000"
                :text="txtEvent"
        />
    </div>
</template>
<script>
import {
    CardUsers,
    SmsError,
    SmsConfirm,
    LoaderBar,
    CardTitle,
    CardActions,
    SmsCorrect,
    Search,
    OptionButton,
    LoaderCircle
} from '../../components/organisms'
import { TextFieldForm, Select, TitleNoData } from '../../components/molecules'
import { rules, responseCase } from '../../mixins'
import { User } from '../../models'
export default {
    mixins: [rules, responseCase],
    components: {
        CardUsers,
        SmsError,
        SmsConfirm,
        LoaderBar,
        CardTitle,
        CardActions,
        TextFieldForm,
        Select,
        SmsCorrect,
        Search,
        OptionButton,
        TitleNoData,
        LoaderCircle
    },
    data: () => ({
        dialogError: false,
        smsError: [],
        actionError: false,
        textConfirm: '',
        dialogConfirm: false,
        dialogBar: false,
        smsLoader: 'Obteniendo Datos',
        dialogForm: false,
        titleForm: '',
        actionForm: '',
        loadingBtnActions: false,
        dataUser: new User(),
        noAccess: false,
        parametroOpcion: 0,
        valid: true,
        showPassword: false,
        listCategory: [{ valor: 0, categoria: 'Administrador' }],
        frameCorrect: false,
        txtEvent: [],
        listUsers: [],
        txtSearchUser: '',
        indexUser: -1,
        idUserHab: -1,
        dialogLoaderCircle: false
    }),
    methods: {
        searchUser() {
            this.listUsers = []
            var idUser = JSON.parse(localStorage.getItem('empresauser')).id
            if (this.txtSearchUser === null) {
                this.getUsers(idUser, '')
            } else {
                this.getUsers(idUser, this.txtSearchUser)
            }
        },
        getUsers(id, search) {
            this.smsLoader = 'Obteniendo Datos'
            this.dialogLoaderCircle = true
            User.getUsers(id, search).then(response => {
                this.listUsers = response
                this.dialogLoaderCircle = false
            }).catch((error) => {
                this.processError(error)
            })
        },
        processError(error) {
            this.smsError = this.smsErrorResponse(error)
            this.actionError = this.actionResponse(error)
            this.noAccess = this.autorization(error)
            this.dialogBar = false
            this.dialogError = true
        },
        selectUser(e) {
            this.smsCorrecto = false
            if (e.param === 1) {
                window.open("https://api.whatsapp.com/send?phone=591" + e.usuario.celular)
            } else {
                this.indexUser = this.listUsers.indexOf(e.usuario)
                this.parametroOpcion = e.param
                if (e.param === 2) {
                    this.idUserHab = e.usuario.id
                    if (e.usuario.habilitado) { this.textConfirm = '¿Está seguro de deshabilitar a ' + e.usuario.nombre +'?' }
                    else { this.textConfirm = '¿Está seguro de habilitar a ' + e.usuario.nombre + '?' }
                    this.dialogConfirm = true
                } else {
                    this.dialogForm = true
                    this.dataUser = Object.assign({}, e.usuario)
                    if (e.param === 3) {
                        this.titleForm = 'Editar Usuario'
                        this.actionForm = 'Editar'
                    } else {
                        this.dataUser.contrasenia = null
                        this.dataUser.repitcontrasenia = null
                        this.titleForm = 'Cambiar Contraseña'
                        this.actionForm = 'Actualizar'
                    }
                }
            }
        },
        validateField(fact, state) {
            if (state) {
                if (!this.emptyField(fact)) {
                    return this.validNumber(fact)
                }
                return true
            }
            else { return !this.emptyField(fact) }
        },
        validateFieldUser(fact) {
            if (!this.emptyField(fact) && !this.withSpaces(fact)) { return true }
            else { return false }
        },
        samePasswords(fact) {
            if (fact !== this.dataUser.contrasenia) { return false }
            else { return true }
        },
        validateFielOther(fact) {
            return this.emptyField(fact)
        },
        closeForm() {
            this.$refs.formuser.resetValidation()
            this.dialogForm = false
            this.dataUser = new User()
            this.indexUser = -1
            this.dialogBar = false
        },
        openForm() {
            this.parametroOpcion = 0
            this.dialogForm = true
            this.titleForm = 'Nuevo Usuario'
            this.actionForm = 'Registrar'
        },
        validateData() {
            var validate = true
            if (!this.$refs.formuser.validate()) { validate = false }
            return validate
        },
        backFormError() {
            if (this.noAccess) {
                localStorage.removeItem('empresauser')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                if (this.parametroOpcion === 0 || this.parametroOpcion === 3 || this.parametroOpcion === 4) {
                    this.dialogForm = true
                }
            }
        },
        backFormConfirmation() {
            this.dialogConfirm = false
            if (this.parametroOpcion === 0 || this.parametroOpcion === 3 || this.parametroOpcion === 4) {
                this.dialogForm = true
            }
        },
        sendToConfirmation() {
            if (!this.validateData()) {
                this.dialogForm = false
                this.smsError = ['Revise los datos marcados con rojo y corrija porfavor']
                this.actionError = true
                this.dialogError = true
            } else {
                if (this.parametroOpcion === 0 || this.parametroOpcion === 3) {
                    this.loadingBtnActions = true
                    if (this.parametroOpcion === 0) {
                        this.textConfirm = '¿Está seguro de registrar el usuario para: ' + this.dataUser.nombre + ' ?'
                    } else {
                        this.textConfirm = '¿Está seguro de editar los datos del usuario: ' + this.dataUser.nombre + ' ?'
                    }
                    // vlaidar username
                    User.validUser(this.parametroOpcion, this.dataUser.id, this.dataUser.username).then(response => {
                        this.loadingBtnActions = false
                        this.dialogForm = false
                        if (response.length > 0) {
                            this.smsError = ['El username ya se encuentra en uso']
                            this.dialogError = true
                            this.actionError = true
                        } else {
                            this.dialogConfirm = true
                        }
                    }).catch((error) => {
                        this.processError(error)
                    })
                } else {
                    this.textConfirm = '¿Está seguro de actualizar la contraseña del usuario: ' + this.dataUser.nombre + ' ?'
                    this.dialogForm = false
                    this.dialogConfirm = true
                }
            }
        },
        showEvent(txt) {
            this.txtEvent = txt
            this.frameCorrect = true
            setTimeout(() => {
                this.frameCorrect = false
            }, 4000)
        },
        performOperation() {
            this.dialogConfirm = false
            this.smsLoader = 'Realizando Operación'
            this.dialogBar = true
            if (this.parametroOpcion === 0) {
                User.addUser(this.dataUser).then(response => {
                    this.closeForm()
                    this.txtSearchUser = response.nombre
                    this.listUsers = [response]
                    this.showEvent(['OERACIÓN EXITOSA!',
                                'El usuario: ' + response.nombre + ' fue registrado'])
                }).catch((error) => {
                    this.processError(error)
                })
            } else {
                if (this.parametroOpcion === 2) {
                    User.enableDisable(this.idUserHab).then(response => {
                        this.listUsers[this.indexUser] = response
                        if (response.habilitado) {
                            var smsExit = 'habilitado'
                        } else {
                            var smsExit = 'inhabilitado'
                        }
                        this.showEvent(['OERACIÓN EXITOSA!',
                                'El usuario: ' + response.nombre + ', fue ' + smsExit + ' con éxito'])
                        this.dialogBar = false
                    }).catch((error) => {
                        this.processError(error)
                    })
                } else {
                    if (this.parametroOpcion === 3) {
                        User.updateDataUser(this.dataUser).then(response => {
                            this.listUsers[this.indexUser] = response
                            this.closeForm()
                            this.showEvent(['OERACIÓN EXITOSA!',
                                'Los datos del usuario: ' + response.nombre + ', fueron editados'])
                        }).catch((error) => {
                            this.processError(error)
                        })
                    } else {
                        var sendDataPassword = {
                            id: this.dataUser.id,
                            username: this.dataUser.username,
                            contrasenia: this.dataUser.contrasenia
                        }
                        User.updateUserPass(sendDataPassword).then(response => {
                            this.listUsers[this.indexUser] = response
                            this.closeForm()
                            this.showEvent(['OERACIÓN EXITOSA!',
                                'La contraseña del usuario: ' + response.nombre + ', fue actualizada'])
                        }).catch((error) => {
                            this.processError(error)
                        })
                    }   
                }
            }
        }
    },
    mounted() {
        this.getUsers(JSON.parse(localStorage.getItem('empresauser')).id, '')
    }
}
</script>