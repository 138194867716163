<template>
    <table class="tablePedido" border="0">
        <thead>
            <tr class="contenidoCabezera">
                <th>PRODUCTO</th>
                <th>PIN</th>
                <th>X</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(pin,index) in listaPins" :key="index">
                <td>{{pin.product}}</td>
                <td class="centrar">{{pin.pin}}</td>
                <td class="centrar">
                    <button class="btnquitarTable" @click="$emit('click',index)">
                        <h1>x</h1>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        listaPins: Array
    }
}
</script>
<style scoped>
    .tablePedido{
        border-spacing: 0px 6px;
    }
    .tablePedido tbody tr{
        background: rgb(236, 238, 238);
        border-radius: 10px !important;
        overflow: hidden;
    }
    .tablePedido thead .contenidoCabezera th{
        border-bottom: 3px solid #020922bb !important;
    }
    .tablePedido thead tr th, .tablePedido tbody tr td{
        font-size: 15px !important;
    }
    .tablePedido thead tr th:nth-child(1),.tablePedido tbody tr td:nth-child(1){
        width: 300px;
    }
    .tablePedido thead tr th:nth-child(2),.tablePedido tbody tr td:nth-child(2){
        width: 90px;
    }
    .tablePedido thead tr th:nth-child(3),.tablePedido tbody tr td:nth-child(3){
        width: 50px;
    }
    .tablePedido thead tr th:nth-child(4),.tablePedido tbody tr td:nth-child(4){
        width: 65px;
    }
    .tablePedido thead tr th:nth-child(5),.tablePedido tbody tr td:nth-child(5){
        width: 40px;
    }
    .tablePedido tbody tr td{
        padding: 5px;
    }
    .centrar{
        text-align: center !important;
    }
    .tablePedido tbody .total{
        background: #004300;
        font-weight: 500;
        color:white;
    }
    .btnquitarTable{
        background: red;
        padding: 0px 5px 0px 5px;
        border-radius: 16px;
        width: 19px;
        height: 19px;
        color: white;
        box-shadow: 0px 0px 3px black;
        border-color:none !important;
    }
    .btnquitarTable h1{
        font-size: 12px;
        font-weight: 400;
    }
    @media (max-width: 520px) {
        .tablePedido thead tr th, .tablePedido tbody tr td{
            font-size: 13px !important;
        }
    }

</style>