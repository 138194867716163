<template>
    <v-icon
        :small="small"
        :large="large"
        :color="color"
    >
        {{icono}}
    </v-icon>
</template>
<script>
export default {
    props: {
        small: Boolean,
        large: Boolean,
        icono: String,
        color: String
    }
}
</script>