<template>
    <div @click="$emit('click')" style="cursor:pointer">
        <v-snackbar
            :value="snakCorrecto"
            :multi-line="multiLine"
            right
            :bottom="true"
            :timeout="timeout"
            :color="colorSucess"
        >
            <div class="d-block">
                <h1 class="text__card--subtitle" v-for="(tex, index) in text" :key="index">{{tex}}</h1>
            </div>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    props: {
        timeout: Number,
        snakCorrecto: Boolean,
        multiLine: Boolean,
        text: Array,
        colorSucess: {
            type: String,
            default: 'success'
        }
    }
}
</script>
<style scoped>
    .text__card--subtitle:first-child{
        color: rgb(227, 235, 228);
    }
</style>