<template>
    <div>
        <v-icon
            x-small color="green"
            class="mr-1"
            v-if="stringValue!=''"
        >mdi-check-circle</v-icon>
        <v-icon
            x-small color="red"
            class="mr-1"
            v-else
        >mdi-close-circle</v-icon>
    </div>
</template>
<script>
export default {
    props: {
        stringValue: String
    }
}
</script>