<template>
    <div class="card-currency d-block">
        <div class="d-flex justify-center mb-2 smsCardPurchase" :style="'background:'+corl_buy_pines">
            <h2 class="text__card--subtitle">{{buy_pines}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">PRODUCTO:</h1>
            <h2 class="text__card--subtitle ml-1">{{purchase.producto.productName}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CANTIDAD SOLICITADA:</h1>
            <h2 class="text__card--subtitle ml-1">{{purchase.quantity}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">CANTIDAD RECIBIDA:</h1>
            <h2 class="text__card--subtitle ml-1">{{purchase.deliveredQuantity}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">N° DE CUPONES:</h1>
            <h2 class="text__card--subtitle ml-1">{{purchase.coupons.length}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">N° DE PINES:</h1>
            <h2 class="text__card--subtitle ml-1">{{num_pines}}</h2>
        </div>
        <div class="d-flex">
            <h1 class="text__card--title">FECHA COMPRA:</h1>
            <h2 class="text__card--subtitle ml-1">{{purchase.fecha}}</h2>
        </div>
        <div class="cart-currency__line"></div>
        <div class="d-flex justify-center">
            <OptionButton
                :outlined="true"
                icono="mdi-file-table-outline"
                coloricono="#06143bb6"
                colorbtn="#06143bb6"
                :small="true"
                class="ma-1"
                @click="$emit('clickdetail', purchase)"
            />
            <OptionButton
                :outlined="true"
                icono="mdi-credit-card-outline"
                coloricono="#06143bb6"
                colorbtn="#06143bb6"
                :small="true"
                class="ma-1"
                @click="$emit('clickcoupon', purchase)"
            />
        </div>
    </div>
</template>
<script>
import { OptionButton } from '../organisms'
export default {
    components: {
        OptionButton
    },
    props:{
        purchase: {}
    },
    computed: {
        num_serials: function() {
            var num_ser = 0
            if (this.purchase.coupons.length > 0){
                for(var i = 0; i < this.purchase.coupons.length; i++) {
                    num_ser = num_ser + this.purchase.coupons[i].serials.length
                }
            }
            return num_ser
        },
        num_pines: function() {
            var num_ser = 0
            if (this.purchase.coupons.length > 0){
                for(var i = 0; i < this.purchase.coupons.length; i++) {
                    num_ser = num_ser + this.purchase.coupons[i].pins.length
                }
            }
            return num_ser
        },
        buy_pines: function() {
            if (this.purchase.coupons.length > 0) {
                var pinsMissing = 0
                var unselledCoupons = this.purchase.coupons.length
                for(var i = 0; i < this.purchase.coupons.length; i++) {
                    var numPins = this.purchase.coupons[i].pins.length
                    for(var k = 0; k < this.purchase.coupons[i].pins.length; k++) {
                        if (this.purchase.coupons[i].pins[k].sold === 1) {
                            numPins -= 1
                        }
                    }
                    if (numPins === 0) { unselledCoupons -= 1 }
                    else { pinsMissing += numPins }
                }
                if (unselledCoupons === 0) {
                    return 'Todos los PINs fueron vendidos'
                } else {
                    if (pinsMissing === 1) { return 'Falta por vender ' + pinsMissing + ' PIN' }
                    else { return 'Falta por vender ' + pinsMissing + ' PINs' }
                }
            }
            return 'Sin cupones'
        },
        corl_buy_pines: function() {
            if (this.purchase.coupons.length > 0) {
                var unselledCoupons = this.purchase.coupons.length
                for(var i = 0; i < this.purchase.coupons.length; i++) {
                    var numPins = this.purchase.coupons[i].pins.length
                    for(var k = 0; k < this.purchase.coupons[i].pins.length; k++) {
                        if (this.purchase.coupons[i].pins[k].sold === 1) {
                            numPins -= 1
                        }
                    }
                    if (numPins === 0) { unselledCoupons -= 1 }
                }
                if (unselledCoupons === 0) {
                    return '#a9e2a9'
                } else {
                    return '#f1cccc'
                }
            }
            return '#a9e2a9'
        }
    }
}
</script>

